import React from 'react';
import { Checkbox, FormControl, FormControlLabel, FormHelperText, Link, Typography } from '@mui/material';
import { styled } from '@mui/system';

const CustomCheckbox = styled(Checkbox)({
  '&&': {
    color: '#3F6EE8',
  }
});

const CustomLink = styled(Link)({
  color: 'black',
  textDecoration: 'underline',
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: 700,
});

const CustomFormControlLabel = styled(FormControlLabel)({
  '&&': {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 500,
    alignItems: 'flex-start',
  }
});

const CustomText = styled('span')({
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: 400,
});

const CustomFormHelperText = styled(FormHelperText)({
  color: 'red',
  fontFamily: 'Inter',
  fontSize: '14px',
});

const TermsCheckbox = ({
  isChecked,
  handleCheckboxChange,
  errors,
  trainerColors
}) => (
  <FormControl error={!!errors.terms}>
    <CustomFormControlLabel
      control={
        <CustomCheckbox
          onChange={handleCheckboxChange}
          checked={isChecked}
          name="termsCheckbox"
          style={{
            color: trainerColors?.accentColor || '#3F6EE8',
          }}
        />
      }
      label={
        <>
          <CustomText
            style={{ color: trainerColors?.textPrimaryColor || 'black' }}
            variant="body2"
          >
            By creating an account you agree to{' '}
          </CustomText>
          <CustomLink
            href={"https://www.appscribe.io/appscribe-terms-and-conditions"}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: trainerColors?.accentColor || '#3F6EE8' }}
          >
            Terms and conditions
          </CustomLink>{' '}
          <CustomText
            variant="body2"
            style={{ color: trainerColors?.textPrimaryColor || 'black' }}
          >
            {' '}and{' '}
          </CustomText>
          <CustomLink
            href={"https://www.appscribe.io/appscribe-privacy-policy"}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: trainerColors?.accentColor || '#3F6EE8' }}
          >
            Privacy policy
          </CustomLink>
        </>
      }
      labelPlacement="end"
    />
    {errors.terms && <CustomFormHelperText>{errors.terms}</CustomFormHelperText>}
  </FormControl>
);

export default TermsCheckbox;
