import { useState, createContext, useEffect } from "react";
import Cookies from "js-cookie";

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(Cookies.get('authToken') || null);
  const [authorId, setAuthorId] = useState(Cookies.get('authorId') || null);
  const [userId, setUserId] = useState(Cookies.get('userId') || null);

  // TODO: implement refresh token
  const [email, setEmail] = useState(Cookies.get('email') || null);
  const [password, setPassword] = useState(Cookies.get('password') || null);

  let loginCredentials = {
    authToken,
    authorId,
    userId,
    email,
    password
  };

  const setLoginCredentials = async (token, authorId, userId, email, password) => {
    const expires = new Date(new Date().getTime() + 20 * 60 * 1000);
    Cookies.set('authToken', token, { expires: expires});
    Cookies.set('authorId', authorId, { expires: expires });
    Cookies.set('userId', userId, { expires: expires });
    Cookies.set('email', email, { expires: expires });
    Cookies.set('password', password, { expires: expires});
    setAuthToken(token);
    setAuthorId(authorId);
    setEmail(email);
    setPassword(password);
  };

  const clearCredentials = () => {
    Cookies.remove('authToken');
    Cookies.remove('authorId');
    Cookies.remove('userId');
    Cookies.remove('email');
    Cookies.remove('password');
    setAuthToken(null);
    setAuthorId(null);
    setEmail(null);
    setPassword(null);
  };

  useEffect(() => {
    setAuthToken(Cookies.get('authToken'));
    setAuthorId(Cookies.get('authorId'));
    setUserId(Cookies.get('userId'));
    setEmail(Cookies.get('email'));
    setPassword(Cookies.get('password'));
  }, []);

  const contextValue = {
    loginCredentials,
    setLoginCredentials,
    clearCredentials
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};
