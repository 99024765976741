import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BackButton from 'src/Elements/BackButton';

import Plans from './Plans';
import CategoriesReducer from 'src/Utilities/CategoriesReducer';
import { NetworkProvider } from 'src/NetworkProvider';
import { AuthContext } from 'src/AuthProvider';

const StateEnum = {
    NONE: -1,
    ADD_WORKOUT_PLAN: 0,
    ADD_EDIT_PLAN: 1,
    LIST_PLANS: 2,
};

const TrainingPlans = ({
    newCategory,
    onCategoryAdded,
    onCategoryUpdated,
    openCategory,
    onPlanClick,
    onClose,
    setLoadingText
}) => {
    const classes = useStyles();
    const [state, setState] = useState(StateEnum.NONE);
    const [editPlan, setEditPlan] = useState(null);
    const { loginCredentials, setLoginCredentials } = useContext(AuthContext);

    const [localCategory, setLocalCategory] = useState(null);

    useEffect(() => {
        if (openCategory) {
            setState(StateEnum.LIST_PLANS);
        } else if (newCategory) {
            setState(StateEnum.ADD_WORKOUT_PLAN)
        }
        setLocalCategory(openCategory)

    }, [openCategory]);

    const handleAddWorkoutPlanClick = () => {
        setState(StateEnum.ADD_EDIT_PLAN);
    }

    const onPlanAdded = async (name, description, type, difficulty, duration, image) => {
        const categoryId = localCategory ? localCategory.id : newCategory.id;

        try {
            let imageUrl = '';
            if (image != null) {
                setLoadingText("Uploading image...")
                imageUrl = await NetworkProvider.upload_image(loginCredentials, setLoginCredentials, image);
            }

            setLoadingText("Adding plan...")
            const planData = await NetworkProvider.add_plan(loginCredentials, setLoginCredentials, categoryId, name, description, type, difficulty, duration, imageUrl);

            planData.weeks = planData.weeks || [];
            if (newCategory) {
                let newCategoryWithPlan = newCategory;
                newCategoryWithPlan.plans = [planData];
                onCategoryAdded(newCategoryWithPlan)
            } else if (localCategory) {
                const updatedCategory = {
                    ...localCategory,
                    plans: [...localCategory.plans, planData]
                };
                onCategoryUpdated(updatedCategory);
                setLocalCategory(updatedCategory);
                setState(StateEnum.LIST_PLANS);
            }
            setLoadingText(null)
        } catch (error) {
            setLoadingText(null)
            window.alert(error);
        }
    }

    const onPlanEdited = async (editedPlan, image) => {
        if (!localCategory) {
            alert("No category to edit plan for")
            return
        }

        try {
            setLoadingText("Updating plan")
            let newPlan = editedPlan

            if (image) {
                const oldImage = editPlan.image
                if (oldImage) {
                    try {
                        await NetworkProvider.delete_image(loginCredentials, setLoginCredentials, oldImage)
                    } catch { }
                }
                const imageUrl = await NetworkProvider.upload_image(loginCredentials, setLoginCredentials, image);
                newPlan.image = imageUrl
            }
            await NetworkProvider.update_plan(loginCredentials, setLoginCredentials, newPlan)

            const updatedPlans = localCategory.plans.map((plan) =>
                plan.id === editedPlan.id ? editedPlan : plan
            );

            const updatedCategory = { ...localCategory, plans: updatedPlans };
            onCategoryUpdated(updatedCategory)
            setLocalCategory(updatedCategory);
            setState(StateEnum.LIST_PLANS);
            setLoadingText(null)
        } catch (error) {
            alert(error)
        }
    };

    const onPlanDeleteClick = async (plan) => {
        if (!localCategory)
            return;

        setLoadingText("Deleting plan...")
        try {
            await CategoriesReducer.deletePlan(loginCredentials, setLoginCredentials, plan)
            await NetworkProvider.delete_plan(loginCredentials, setLoginCredentials, plan.id);
            const updatedPlans = localCategory.plans.filter((p) => p.id !== plan.id);
            const updatedCategory = { ...localCategory, plans: updatedPlans };
            onCategoryUpdated(updatedCategory);
            setLocalCategory(updatedCategory);
            setLoadingText(null)
        } catch (error) {
            setLoadingText(null)
            alert(error);
        }
    }


    const onPlanEditClick = (plan) => {
        setEditPlan(plan);
        setState(StateEnum.ADD_EDIT_PLAN);
    }

    const onAddWorkoutPlanClick = () => {
        setState(StateEnum.ADD_EDIT_PLAN);
    }

    return (
        <div>
            <BackButton onClick={onClose} />
            <h2 className={classes.mainTitle}>
                {localCategory ? `Plans > ${localCategory.name}` : (newCategory ? `Categories > ${newCategory.name}` : 'Categories')}
            </h2>
            <div className={classes.contentArea}>
                <Plans
                    state={state}
                    StateEnum={StateEnum}
                    localCategory={localCategory}
                    newCategory={newCategory}
                    editPlan={editPlan}
                    onAddWorkoutPlanClick={onAddWorkoutPlanClick}
                    handleAddWorkoutPlanClick={handleAddWorkoutPlanClick}
                    onPlanClick={onPlanClick}
                    onPlanAdded={onPlanAdded}
                    onPlanEdited={onPlanEdited}
                    onPlanDeleteClick={onPlanDeleteClick}
                    onPlanEditClick={onPlanEditClick}
                />
            </div>
        </div>
    );
};

const useStyles = makeStyles({
    mainTitle: {
        fontSize: 29,
        fontFamily: 'Inter',
        fontWeight: 600,
        color: '#000000',
    },
    contentArea: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    mainContent: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingRight: 20,
    },
    workoutPlanContent: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        paddingRight: 20,
    },
    plansContent: {
        display: 'flex',
        gap: 20,
        flexWrap: 'wrap',
    },
    workoutPlanContainer: {
        width: '70%',
    },
    exampleContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    categoriesExampleIcon: {
        maxWidth: 350,
    },
    exampleTitle: {
        fontSize: 16,
        fontFamily: 'Inter',
        fontWeight: 400,
        marginBottom: 5,
        color: '#000000',
    },
    addWorkoutPlanButton: {
        width: '250px'
    },
    title: {
        textAlign: 'left',
        fontSize: 16,
        fontFamily: 'Inter',
        fontWeight: 600,
        color: '#16192C',
    },
    subtitle: {
        textAlign: 'left',
        fontSize: 14,
        fontFamily: 'Inter',
        fontWeight: 500,
        color: '#425466',
    },
    description: {
        textAlign: 'left',
        fontSize: 14,
        fontFamily: 'Inter',
        fontWeight: 400,
        color: '#737373',
        marginBottom: 30
    },
    textField: {
        width: '100%',
        marginBottom: 20,
    },
    imageUploadContainer: {
        marginTop: 10,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
        backgroundColor: 'white',
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        padding: 20
    },
    imagePreview: {
        width: 113,
        height: 113,
        borderRadius: '8px',
    },
    imageUploadTitle: {
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: '16px',
        color: '#16192C',
        paddingLeft: 10
    },
    uploadButtonContainer: {
        width: '100px'
    },
    continueButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '100%'
    },
    plansListContainer: {
        width: '100%'
    },
    header: {
        backgroundColor: 'white',
        padding: 5,
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        marginBottom: 100,
        width: '100%'
    },
    headerButton: {
        marginLeft: 'auto',
    },
});

export default TrainingPlans;
