import React, { useState, useImperativeHandle, forwardRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import BlueButton from "src/Elements/BlueButton";
import WhiteTextField from "src/Elements/WhiteTextField";
import DOMPurify from "dompurify";
import { MAX_PUSH_TITLE_CHARS, MAX_PUSH_BODY_CHARS } from 'src/contants';

const NotificationsForm = forwardRef(({ onSubmit }, ref) => {
    const classes = useStyles();
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [titleError, setTitleError] = useState('');
    const [bodyError, setBodyError] = useState('');

    useImperativeHandle(ref, () => ({
        clearFields: () => {
            setTitle('');
            setBody('');
            setTitleError('');
            setBodyError('');
        }
    }));

    const validateForm = () => {
        let isValid = true;
        if (title.length < 3) {
            setTitleError("Title must be at least 3 characters long");
            isValid = false;
        } else if (title.length > MAX_PUSH_TITLE_CHARS) {
            setTitleError(`Title must not exceed ${MAX_PUSH_TITLE_CHARS} characters`);
            isValid = false;
        } else {
            setTitleError('');
        }

        if (body.length < 3) {
            setBodyError("Body must be at least 3 characters long");
            isValid = false;
        } else if (body.length > MAX_PUSH_BODY_CHARS) {
            setBodyError(`Body must not exceed ${MAX_PUSH_BODY_CHARS} characters`);
            isValid = false;
        } else {
            setBodyError('');
        }

        return isValid;
    };

    const handleSubmit = () => {
        if (validateForm()) {
            onSubmit(title, body);
        }
    };

    const handleTitleChange = (event) => {
        const sanitizedTitle = DOMPurify.sanitize(event.target.value);
        if (sanitizedTitle.length <= MAX_PUSH_TITLE_CHARS) {
            setTitle(sanitizedTitle);
        }
    };

    const handleBodyChange = (event) => {
        const sanitizedBody = DOMPurify.sanitize(event.target.value);
        if (sanitizedBody.length <= MAX_PUSH_BODY_CHARS) {
            setBody(sanitizedBody);
        }
    };

    return (
        <div className={classes.formContainer}>
            <WhiteTextField
                label={`Notification title (Max ${MAX_PUSH_TITLE_CHARS} chars)`}
                variant="outlined"
                className={classes.textField}
                error={Boolean(titleError)}
                helperText={titleError}
                value={title}
                onChange={handleTitleChange}
                inputProps={{ maxLength: MAX_PUSH_TITLE_CHARS }}
            />
            <WhiteTextField
                label={`Notification body (Max ${MAX_PUSH_BODY_CHARS} chars)`}
                variant="outlined"
                className={classes.textField}
                error={Boolean(bodyError)}
                helperText={bodyError}
                value={body}
                onChange={handleBodyChange}
                inputProps={{ maxLength: MAX_PUSH_BODY_CHARS }}
            />
            <div className={classes.submitButtonContainer}>
                <BlueButton onClick={handleSubmit}>Submit</BlueButton>
            </div>
        </div>
    );
});

const useStyles = makeStyles(() => ({
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
    },
    textField: {
        marginBottom: '20px',
        width: '100%'
    },
    submitButtonContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    }
}));

export default NotificationsForm;
