import { PDFDocument, rgb } from 'pdf-lib';
import contractPdf from 'src/Contract.pdf';

async function generateContractPDF(username, email, address) {
    const response = await fetch(contractPdf);
    const pdfBytes = await response.arrayBuffer();
    const pdfDoc = await PDFDocument.load(pdfBytes);

    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    firstPage.drawText(`${username}`, {
        x: 170,
        y: 545,
        size: 12,
        color: rgb(0, 0, 0),
    });

    if (email) {
        firstPage.drawText(`${email}`, {
            x: 140,
            y: 531,
            size: 12,
            color: rgb(0, 0, 0),
        });
    }

    if (address) {
        firstPage.drawText(`${address}`, {
            x: 155,
            y: 517,
            size: 12,
            color: rgb(0, 0, 0),
        });
    }

    return await pdfDoc.save()
}

export default generateContractPDF;