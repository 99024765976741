import React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';
import { OnboardingState } from 'src/Components/Onboarding/Enums';

const Container = styled('div')({
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: 600,
  textAlign: 'center',
});

const MessageText = styled('span')({
  fontSize: '12px',
  fontFamily: 'Inter',
  fontWeight: 600
});

const SignInButton = styled(Button)({
  fontWeight: 600,
  fontFamily: 'Inter',
  fontSize: '12px',
  textDecoration: 'underline',
  textTransform: 'none',
  '&:hover': {
    textDecoration: 'none',
  },
});

const OnboardingPrompt = ({
  onClick,
  onboardingState,
  trainerColors
}) => {
  let elements = [];

  switch (onboardingState) {
    case OnboardingState.CREATE_ACCOUNT:
      elements.push(
        <MessageText style={{
          color: trainerColors?.textSecondaryColor || '#878787'
        }}>
          Don't have an account?
        </MessageText>,
        <SignInButton
          key="signup"
          onClick={onClick}
          style={{
            color: trainerColors?.accentColor || '#316CE0'
          }}>
          Sign up
        </SignInButton>
      );
      break;
    case OnboardingState.FORGOT_PASSWORD:
      elements.push(
        <MessageText style={{
          color: trainerColors?.textSecondaryColor || '#878787'
        }}>
          Forgot your password?
        </MessageText>,
        <SignInButton
          onClick={onClick}
          style={{
            color: trainerColors?.accentColor || '#316CE0'
          }}>
          Reset password
        </SignInButton>
      );
      break;
    case OnboardingState.SIGN_IN:
      elements.push(
        <MessageText
          key="message1"
          style={{
            color: trainerColors?.textSecondaryColor || '#878787'
          }}>
          Already have an account?
        </MessageText>,
        <SignInButton
          key="button1"
          onClick={onClick}
          style={{
            color: trainerColors?.accentColor || '#316CE0'
          }}
        >Sign in</SignInButton>
      );
      break;
    default:
      break;
  }

  return (
    <Container>
      {elements}
    </Container>
  );
};

export default OnboardingPrompt;
