
class VipCodesReducer {

    static sortedCodes = (codes) => {
        return codes.sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at);
        });
    };
}

export default VipCodesReducer;