import React, { useState, useEffect, useContext } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { AuthContext } from 'src/AuthProvider';
import colors from 'src/colors.json';
import trainers from 'src/trainers.json';
import { makeStyles } from "@mui/styles";
import { Radio, Card, CardContent, Button, Typography, Box } from "@mui/material";

import PaymentMethodForm from 'src/Components/Payments/PaymentMethodForm';
import { NetworkProvider } from 'src/NetworkProvider';
import { STRIPE_PK_KEY } from 'src/contants';
import CardIcon from 'src/Utilities/CardIcon/CardIcon';
import "./PaymentMethods.css"
import Popup from 'src/Components/Popup/Popup';

const PaymentMethods = ({ setLoadingText, trainer }) => {
    const classes = useStyles();
    const [showAddPayment, setShowAddPayment] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [customer, setCustomer] = useState(null);
    const [isAddPaymentOpen, setIsAddPaymentOpen] = useState(true);
    const [collapsed, setCollapsed] = useState(false);
    const { loginCredentials, setLoginCredentials } = useContext(AuthContext);

    const trainerColors = colors[trainer] || colors['default']
    let trainerInfo;
    let stripeKey = STRIPE_PK_KEY;


    if (trainer) {
        trainerInfo = trainers[trainer]
        stripeKey = trainerInfo["stripe_public_key"]
    }

    const stripePromise = loadStripe(stripeKey, { locale: 'en' });

    const handleWindowResize = () => {
        setCollapsed(window.innerWidth < 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    useEffect(() => {
        setupPaymentMethods();
    }, []);

    const handleAddPaymentClick = () => {
        setEditMode(false);
        setShowAddPayment(true);
    };

    const setDefaultPaymentMethod = async (paymentMethodId) => {
        try {
            setLoadingText("Setting default payment method")
            if (trainer) {
                await NetworkProvider.set_user_default_payment_method(loginCredentials, setLoginCredentials, paymentMethodId, trainer);
            } else {
                await NetworkProvider.set_default_payment_method(loginCredentials, setLoginCredentials, paymentMethodId);
            }
            setupPaymentMethods();

        } catch (error) {
            setLoadingText(null)
            window.alert(error);
        }
    };

    const setupPaymentMethods = async () => {
        try {
            let paymentMethodsData;
            let customerData;

            if (trainer) {
                paymentMethodsData = await NetworkProvider.get_user_payment_methods(loginCredentials, setLoginCredentials, trainer);
                customerData = await NetworkProvider.get_user_customer_info(loginCredentials, setLoginCredentials, trainer);
            } else {
                paymentMethodsData = await NetworkProvider.get_payment_methods(loginCredentials, setLoginCredentials);
                customerData = await NetworkProvider.get_customer_info(loginCredentials, setLoginCredentials);
            }
            setCustomer(customerData);
            setPaymentMethods(paymentMethodsData);
            setLoadingText(null)
        } catch (error) {
            setLoadingText(null)
            window.alert(error);
        }
    };

    const onPaymentMethodCreated = (paymentMethod) => {
        if (paymentMethods.length == 0) {
            const paymentMethodId = paymentMethod.id;
            setDefaultPaymentMethod(paymentMethodId)
        } else {
            setPaymentMethods([...paymentMethods, paymentMethod]);
        }
        setShowAddPayment(false);
    };

    const handlePaymentMethodDelete = async (paymentMethodId) => {
        try {
            setLoadingText("Deleting payment method")
            if (trainer) {
                await NetworkProvider.delete_user_payment_method(loginCredentials, setLoginCredentials, paymentMethodId, trainer);
            } else {
                await NetworkProvider.delete_payment_method(loginCredentials, setLoginCredentials, paymentMethodId);
            }
            setPaymentMethods(paymentMethods.filter((pm) => pm.id !== paymentMethodId));
            setLoadingText(null)
        } catch (error) {
            setLoadingText(null)
            window.alert(error);
        }
    };

    return (
        <div className='payment-methods-card'>
            <Card className={classes.card}>
                <CardContent
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        width: "93%"
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: '20px'
                        }}
                    >
                        <Typography
                            variant="h10"
                            component="p"
                            sx={{
                                fontSize: "16px",
                                fontWeight: 600,
                                fontStyle: "bold",
                            }}
                        >
                            Payment Methods
                        </Typography>
                        <Button
                            onClick={() => setEditMode(!editMode)}
                            sx={{
                                color: trainerColors?.accentColor,
                                textTransform: "none",
                                fontSize: "14px",
                                fontWeight: 600
                            }}
                        >
                            {editMode ? "Done" : "Edit"}
                        </Button>
                    </Box>
                    <ul className={classes.listContainer}>
                        {paymentMethods.map((paymentMethod) => (
                            <li
                                key={paymentMethod.id}
                                className={classes.listItem}
                                style={{ backgroundColor: '#EDF2F7' }}
                            >
                                <div className={classes.brandSection}>
                                    <CardIcon
                                        brand={paymentMethod.card.brand}
                                        color={trainerColors?.accentColor}
                                    />
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                        marginLeft: '20px'
                                    }}>
                                        <Typography
                                            sx={{
                                                fontSize: "14px",
                                                fontWeight: 400,
                                                color: trainerColors?.textPrimaryColor
                                            }}
                                        >
                                            {`${paymentMethod.card.brand}: `}
                                            <Typography
                                                component="span"
                                                sx={{
                                                    fontWeight: 700,
                                                }}
                                            >
                                                {paymentMethod.card.last4}
                                            </Typography>
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: "14px",
                                                fontWeight: 400,
                                                color: trainerColors?.textPrimaryColor
                                            }}
                                        >
                                            Exp. date:{" "}
                                            <Typography
                                                component="span"
                                                sx={{
                                                    fontWeight: 700
                                                }}
                                            >
                                                {`${paymentMethod.card.exp_month}/${paymentMethod.card.exp_year}`}
                                            </Typography>
                                        </Typography>
                                    </div>
                                </div>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: "8px",
                                        flex: 1,
                                        marginRight: '20px'
                                    }}
                                >
                                    {editMode ? <Button
                                        onClick={() => handlePaymentMethodDelete(paymentMethod.id)}
                                        sx={{
                                            backgroundColor: "red",
                                            color: "white",
                                            textTransform: "none",
                                            borderRadius: "50%",
                                            width: "26px",
                                            height: "26px",
                                            padding: 0,
                                            minWidth: "26px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            fontSize: "12px",
                                            fontWeight: "bold",
                                            "&:hover": {
                                                backgroundColor: "darkred"
                                            },
                                        }}
                                    >
                                        x
                                    </Button> :
                                        <Radio
                                            value={paymentMethod.id}
                                            checked={paymentMethod.id == customer.invoice_settings.default_payment_method}
                                            onChange={() => setDefaultPaymentMethod(paymentMethod.id)}
                                            sx={{
                                                color: trainerColors?.accentColor,
                                                "&.Mui-checked": {
                                                    color: trainerColors?.accentColor,
                                                },
                                            }}
                                        />}
                                </Box>
                            </li>
                        ))}
                    </ul>
                    <Button
                        onClick={handleAddPaymentClick}
                        sx={{
                            width: '100%',
                            background: "var(--grey-100, #F5F5F5)",
                            color: trainerColors?.textPrimaryColor,
                            textTransform: "none",
                            borderRadius: "var(--borderRadius, 4px)",
                            fontWeight: "6000",
                            fontSize: "0.875rem",
                            boxShadow: "0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.20)",
                            display: "inline-flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            height: '46px',
                            "&:hover": {
                                backgroundColor: trainerColors?.primaryColor,
                            },
                        }}
                    >
                        Add payment method
                    </Button>
                </CardContent>
            </Card>
            {showAddPayment && (
                <Popup
                    onClose={() => setShowAddPayment(false)}
                    isOpenProp={isAddPaymentOpen}
                    trainerColors={trainerColors}
                >
                    <Elements stripe={stripePromise}>
                        <PaymentMethodForm
                            onPaymentMethodCreate={onPaymentMethodCreated}
                            setLoadingText={setLoadingText}
                            trainer={trainer}
                            trainerColors={trainerColors}
                        />
                    </Elements>
                </Popup>
            )}
        </div>
    );
};

const useStyles = makeStyles({
    card: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: '100%',
        maxWidth: 500,
        marginTop: '20px',
        textAlign: "center",
        border: "1px solid #ddd",
        borderRadius: 12,
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#fff",
    },
    listContainer: {
        borderRadius: "10px",
        listStyleType: "none",
        margin: 0,
        paddingLeft: 0,
        width: "100%"
    },
    listItem: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: "8px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        height: '90px',
        width: '100%',
        marginBottom: '20px',
    },
    brandSection: {
        display: "flex",
        alignItems: "center",
        flex: 10,
        marginLeft: '10px'
    },
    cardText: {
        marginLeft: "10px",
        fontSize: "1rem",
    },
    radioButton: {
        alignItems: "flex-start",
        justifyContent: "flex-end",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    outerCircle: {
        width: '21px',
        height: '21px',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    innerCircle: {
        width: '19px',
        height: '19px',
        borderRadius: '50%',
    },
});

export default PaymentMethods;