import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { FORGOT_PASSWORD_TITLE, FORGOT_PASSWORD_SUBTITLE, SIGN_IN_URI } from 'src/contants';
import { useNavigate } from 'react-router-dom';
import { NetworkProvider } from 'src/NetworkProvider';
import FullScreenLoader from 'src/Elements/FullScreenLoader';
import { UserTextFields } from '../UserTextfields/UserTextFields.jsx';
import { OnboardingState } from '../Enums';
import OnboardingPrompt from 'src/Elements/OnboardingPrompt';
import colors from 'src/colors.json';
import usePaymentStyles from '../PaymentOnboarding/PaymentStyles.js';

function ForgotPassword() {
    const classes = usePaymentStyles();
    const navigate = useNavigate();
    const [loadingText, setLoadingText] = useState(null);

    const logoPath = require(`src/Assets/Logos/appscribe.png`);
    const trainerColors = colors['default']

    async function handleForgotPassword(formData, captchaToken) {
        if (!captchaToken) {
            alert('Failed to validate captcha');
            return;
        }

        try {
            await NetworkProvider.validateCaptchaToken(captchaToken);
        } catch (error) {
            alert('Failed to validate captcha ', error);
            return;
        }

        setLoadingText('Sending reset link');

        try {
            const { email } = formData;
            await NetworkProvider.send_password_reset_link(email);
            alert('Password reset email will be sent if such email is found in our system');
        } catch (error) {
            alert('Error sending password reset link');
        }

        setLoadingText(null);
        handleSignIn();
    }

    function handleSignIn() {
        let signInUri = SIGN_IN_URI;
        navigate(signInUri);
    }

    function onFailedValidatingCaptcha(error) {
        setLoadingText(null);
        alert('Failed to validate captcha: ' + error);
    }

    return (
        <div className={classes.root}>
            <Helmet>
                <script type="application/ld+json">
                    {`
                {
                    "@context": "https://schema.org",
                    "@type": "WebPage",
                    "name": "Onboarding",
                    "description": "Introduce Artificial intelligence power. Stay ahead of your competition with the best customer service 24/7.",
                    "url": "${window.location.href}"
                }
            `}
                </script>
            </Helmet>
            <div className={classes.cardContainer}>
                <div className={classes.logoContainer}>
                    <img src={logoPath} alt="Logo" className={classes.logo} />
                    <span className={classes.logoText}>{"Appscribe"}</span>
                </div>
                <div className={classes.card}
                    style={{
                        backgroundColor: trainerColors?.primaryColor,
                        color: trainerColors?.textPrimaryColor
                    }}>
                    <div className={classes.titleText}>Enter your email</div>
                    <UserTextFields
                        onboardingState={OnboardingState.FORGOT_PASSWORD}
                        onSubmit={handleForgotPassword}
                        onValidatingCaptcha={() => setLoadingText("Validating captcha")}
                        onFailedValidatingCaptcha={onFailedValidatingCaptcha}
                        trainerColors={trainerColors}
                    />
                    <OnboardingPrompt
                        onClick={handleSignIn}
                        onboardingState={OnboardingState.SIGN_IN}
                        trainerColors={trainerColors}
                    />
                </div>
                <FullScreenLoader loadingText={loadingText} />
            </div>
        </div>
    );
}

export default ForgotPassword;
