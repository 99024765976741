import React, { useState, useContext, useEffect } from 'react';
import { TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CategoriesContext } from '../../CategoriesContext';
import BlueButton from 'src/Elements/BlueButton';
import BlueBorderButton from 'src/Elements/BlueBorderButton';

const ExercisePicker = ({ onClose, onExerciseSelected }) => {
    const classes = useStyles();
    const { exercises } = useContext(CategoriesContext);

    const [searchTerm, setSearchTerm] = useState('');
    const [selectedExercise, setSelectedExercise] = useState(null);


    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleExerciseClick = (exercise) => {
        setSelectedExercise(exercise);
    };

    const onSelectClick = () => {
        onExerciseSelected(selectedExercise)
    }

    const filteredExercises = exercises.filter((exercise) =>
        exercise.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        exercise.description.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className={classes.container}>
            <TextField
                label="Search existing exercises"
                variant="outlined"
                fullWidth
                value={searchTerm}
                onChange={handleSearchChange}
                className={classes.searchInput}
            />

            {/* Table of exercises */}
            <TableContainer component={Paper} className={classes.tableContainer}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Description</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredExercises.map((exercise, index) => (
                            <TableRow
                                key={index}
                                onClick={() => handleExerciseClick(exercise)}
                                className={selectedExercise === exercise ? classes.selectedRow : classes.row}
                            >
                                <TableCell>{exercise.name}</TableCell>
                                <TableCell>{exercise.description}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <div className={classes.bottomButtonContainer}>
                {selectedExercise ?
                    <BlueButton onClick={onSelectClick} className={classes.closeButton}>
                        Select
                    </BlueButton> : <div></div>
                }
                <BlueBorderButton onClick={onClose} className={classes.closeButton}>
                    Close
                </BlueBorderButton>
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: '#fff',
        padding: theme.spacing(4),
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        maxWidth: '600px',
        width: '50%',
        maxHeight: '80%',
        margin: '0 auto',
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    searchInput: {
        marginBottom: theme.spacing(2),
    },
    tableContainer: {
        marginBottom: theme.spacing(2),
    },
    closeButton: {
        marginTop: theme.spacing(2),
    },
    row: {
        cursor: 'pointer'
    },
    selectedRow: {
        backgroundColor: '#f0f8ff',
        border: '2px solid #007bff'
    },
    bottomButtonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: '10px',
        '& > *': {
            flex: '0 0 48%',
        },
    },
}));

export default ExercisePicker;
