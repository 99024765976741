import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AuthContext } from 'src/AuthProvider';
import FullScreenLoader from 'src/Elements/FullScreenLoader';
import { UserTextFields } from '../UserTextfields/UserTextFields.jsx';
import { OnboardingState } from '../Enums.jsx';
import OnboardingPrompt from 'src/Elements/OnboardingPrompt';
import { SIGN_IN_URI, SUB_URI } from 'src/contants';
import { NetworkProvider } from 'src/NetworkProvider';
import colors from 'src/colors.json';
import trainers from 'src/trainers.json';
import usePaymentStyles from './PaymentStyles.js';

function CreateAccountForTrainer() {
  const classes = usePaymentStyles();
  const navigate = useNavigate();
  const [loadingText, setLoadingText] = useState(null);
  const { setLoginCredentials } = useContext(AuthContext);
  const [searchParams] = useSearchParams();

  const trainer = searchParams.get('trainer');
  const trainerColors = colors[trainer] || colors['default']
  const trainerInfo = trainers[trainer]
  const trainerName = trainerInfo["name"]

  let logoPath;

  try {
    logoPath = require(`src/Assets/Logos/${trainer}.png`);
  } catch (error) {
    console.error(`Logo not found for trainer: ${trainer}`, error);
  }


  async function handleCreateAccount(formData, captchaToken) {
    try {
      await NetworkProvider.validateCaptchaToken(captchaToken);
    } catch (error) {
      alert('Failed to validate captcha ', error);
      return;
    }

    const { email, password } = formData;
    const name = "New";
    const surname = "User"
    setLoadingText('Registering');

    try {
      await NetworkProvider.insert_user(name, surname, email, password, trainerInfo.id)
      const tokenData = await NetworkProvider.acquire_user_token(email, password, trainerInfo.id);
      const { access_token, user_id } = tokenData;

      setLoginCredentials(access_token, trainerInfo.id, user_id, email, password);
      setLoadingText(null);
      let subUri = SUB_URI;
      if (trainer) {
        subUri += "/?trainer=" + trainer
      }
      navigate(subUri, { state: { email, name, surname } });
    } catch (error) {
      setLoadingText(null);
      window.alert(error.detail || error);
    }
  }

  function handleSignIn() {
    let signInUri = SIGN_IN_URI;
    if (trainer) {
      signInUri += "/?trainer=" + trainer
    }
    navigate(signInUri);
  }

  function onFailedValidatingCaptcha(error) {
    setLoadingText(null);
    alert('Failed to validate captcha' + error);
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <script type="application/ld+json">
          {`
                {
                    "@context": "https://schema.org",
                    "@type": "WebPage",
                    "name": "Create an account",
                    "description": "Create an account in the best influencer monetisation platform",
                    "url": "${window.location.href}"
                }
            `}
        </script>
      </Helmet>
      <div className={classes.cardContainer}>
        <div className={classes.logoContainer}>
          <img src={logoPath} alt="Logo" className={classes.logo} />
          <span className={classes.logoText}>{trainerName}</span>
        </div>
        <div className={classes.card}
          style={{
            backgroundColor: trainerColors?.primaryColor,
            color: trainerColors?.textPrimaryColor
          }}>
          <div className={classes.titleText}>Create an account</div>
          <UserTextFields
            onboardingState={OnboardingState.SHORT_CREATE_ACCOUNT}
            onSubmit={handleCreateAccount}
            onValidatingCaptcha={() => setLoadingText('Validating captcha')}
            onFailedValidatingCaptcha={onFailedValidatingCaptcha}
            trainerColors={trainerColors}
          />
          <OnboardingPrompt
            onClick={handleSignIn}
            onboardingState={OnboardingState.SIGN_IN}
            trainerColors={trainerColors}
          />
        </div>
      </div>
      <FullScreenLoader loadingText={loadingText} />
    </div>
  );
}

export default CreateAccountForTrainer;
