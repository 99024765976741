import { makeStyles } from '@mui/styles';

const useAdminStyles = makeStyles({
  adminContent: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'space-between',
    backgroundColor: '#F8FAFC',
    minHeight: '100vh',
  },
  sideMenu: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: 200,
    backgroundColor: '#252525',
    position: 'fixed',
    height: '100%',
    transition: 'all 0.3s ease',
    boxShadow: '2px 0 4px rgba(0, 0, 0, 0.1)',
    zIndex: 1000,
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    backdropFilter: "blur(1px)",
    WebkitBackdropFilter: "blur(1px)",
    zIndex: 2
  },
  seperatorMenu: {
    marginTop: 20,
    height: 20,
    width: '90%',
    borderTop: '1px solid #dadada',
  },
  LDContainer: {
    marginBottom: 30,
  },
  menuItemContainer: {
    display: 'flex',
    marginTop: 20,
    flexDirection: 'column',
    marginBottom: 50,
    '& button': {
      display: 'flex',
      marginBottom: 8,
      marginLeft: 10,
      marginRight: 10,
      padding: 10,
      color: '#A1A1A1',
      backgroundColor: 'transparent',
      border: '0px solid #ccc',
      cursor: 'pointer',
      fontSize: 14,
      borderRadius: 10,
      fontWeight: 400,
      fontFamily: "'Inter', sans-serif",
      '&:hover': {
        fontSize: 15,
      },
      '&.selected': {
        color: 'white',
        fontSize: 15,
        fontWeight: 600,
      },
    },
  },
  content: {
    flexGrow: 1,
    padding: 16,
    marginLeft: 200,
    transition: 'margin-left 0.3s ease',
    backgroundColor: '#F8FAFC',
    '@media (max-width: 768px)': {
      marginLeft: 0,
      paddingTop: 20,
    },
  },
  menuToggleBtn: {
    display: 'none',
    position: 'fixed',
    left: 16,
    top: 16,
    zIndex: 1,
    backgroundColor: '#f0f0f0',
    border: 'none',
    fontSize: 24,
    padding: 8,
    '@media (max-width: 768px)': {
      display: 'block',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 16px',
    height: '64px'
  },
  logoHolder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    gap: '8px'
  },
  logoText: {
    fontSize: '18px', // Adjust font size as needed
    fontWeight: 'bold', // Make the text bold
    color: '#000', // Adjust color as needed
  },
  menuIcon: {
    borderRadius: 10,
    width: 40,
    height: 40
  },
  backBtn: {
    display: 'none',
    alignItems: 'center', // Centers items vertically
    justifyContent: 'center',
    '@media (max-width: 768px)': {
      display: 'flex'
    },
  },
});

export default useAdminStyles;
