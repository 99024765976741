import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet';

import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'src/AuthProvider';
import FullScreenLoader from 'src/Elements/FullScreenLoader';

import { UserTextFields } from '../UserTextfields/UserTextFields.jsx';
import { OnboardingState } from '../Enums';
import OnboardingPrompt from 'src/Elements/OnboardingPrompt';

import { SIGN_IN_URI, COMPANY_SETUP_URI } from 'src/contants';
import { NetworkProvider } from 'src/NetworkProvider';

import colors from 'src/colors.json';
import usePaymentStyles from '../PaymentOnboarding/PaymentStyles';


function Onboarding() {

  const classes = usePaymentStyles();
  const navigate = useNavigate();
  const [loadingText, setLoadingText] = useState(null);
  const { setLoginCredentials } = useContext(AuthContext);

  const logoPath = require(`src/Assets/Logos/appscribe.png`);
  const trainerColors = colors['default']

  async function handleCreateAccount(formData, captchaToken) {

    try {
      await NetworkProvider.validateCaptchaToken(captchaToken)
    } catch (error) {
      alert("Failed to validate captcha ", error)
      return
    }

    const { name, surname, email, password, coupon } = formData;
    setLoadingText("Registering")
    NetworkProvider.insert_author(name, surname, email, password)
      .then(data => {
        NetworkProvider.acquire_token(email, password)
          .then(tokenData => {
            const { access_token, author_id } = tokenData;
            setLoginCredentials(access_token, author_id, null, email, password);

            setLoadingText(null)
            navigate(COMPANY_SETUP_URI, { state: { email: email, name: name, surname: surname, coupon: coupon } });
          })
          .catch(error => {
            setLoadingText(null)
            window.alert(error.detail || error);
          });
      })
      .catch(error => {
        setLoadingText(null)
        window.alert(error.detail || error);
      });
  }

  function handleSignIn() {
    navigate(SIGN_IN_URI);
  }

  function onFailedValidatingCaptcha(error) {
    setLoadingText(null);
    alert("Failed to validate captcha" + error)
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <script type="application/ld+json">
          {`
                {
                    "@context": "https://schema.org",
                    "@type": "WebPage",
                    "name": "Create an account",
                    "description": "Create an account in the best influencer monetisation platform",
                    "url": "${window.location.href}"
                }
            `}
        </script>
      </Helmet>
      <div className={classes.cardContainer}>
        <div className={classes.logoContainer}>
          <img src={logoPath} alt="Logo" className={classes.logo} />
          <span className={classes.logoText}>{"Appscribe"}</span>
        </div>
        <div className={classes.card}
          style={{
            backgroundColor: trainerColors?.primaryColor,
            color: trainerColors?.textPrimaryColor
          }}>
          <div className={classes.titleText}>Create an account</div>
          <UserTextFields
            onboardingState={OnboardingState.SHORT_CREATE_ACCOUNT}
            onSubmit={handleCreateAccount}
            onValidatingCaptcha={() => setLoadingText('Validating captcha')}
            onFailedValidatingCaptcha={onFailedValidatingCaptcha}
            trainerColors={trainerColors}
          />
          <OnboardingPrompt
            onClick={handleSignIn}
            onboardingState={OnboardingState.SIGN_IN}
            trainerColors={trainerColors}
          />
        </div>
      </div>
      <FullScreenLoader loadingText={loadingText} />
    </div>
  );
}

export default Onboarding;