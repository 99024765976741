import React, { useState, useEffect } from "react";
import { useElements, useStripe, CardElement } from "@stripe/react-stripe-js";
import './CompanyDetailsForm.css'
import availableCountries from "../../../../Utilities/Countries";
import BlueButton from "src/Elements/BlueButton";

const CompanyDetailsForm = ({
    name,
    surname,
    email,
    onCompanyDetailsReady,
    onComplete,
    onFailed,
    initialDetails,
    requirePayment,
    submitTitle,
    editName = false,
    showCoupon,
    trainerColors
}) => {
    const stripe = useStripe();
    const elements = useElements();

    const [userName, setUserName] = useState(name);
    const [userSurname, setUserSurname] = useState(surname);
    const [companyName, setCompanyName] = useState(null);

    const [companyDetails, setCompanyDetails] = useState({
        name: '',
        email: email,
        coupon: '',
        address: {
            line1: '',
            city: '',
            postal_code: '',
            country: ''
        }
    });

    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (initialDetails) {
            const adjustedDetails = JSON.parse(JSON.stringify(initialDetails));
            const country = availableCountries.find((c) => c.code === adjustedDetails.address.country);
            adjustedDetails.address.country = country?.name
            adjustedDetails.email = email;
            setCompanyDetails(adjustedDetails);

            if (initialDetails.metadata?.customer_type == 'business') {
                setCompanyName(adjustedDetails.name)
            }
        }
    }, [initialDetails]);

    const updateCompanyName = (newName) => {
        setCompanyName(newName);
    };

    const updateCountry = (newCountry) => {
        setCompanyDetails((prevDetails) => ({
            ...prevDetails,
            address: {
                ...prevDetails.address,
                country: newCountry
            }
        }));
    };

    const updateAddress = (newAddress) => {
        setCompanyDetails((prevDetails) => ({
            ...prevDetails,
            address: {
                ...prevDetails.address,
                line1: newAddress
            }
        }));
    };

    const updateCity = (newCity) => {
        setCompanyDetails((prevDetails) => ({
            ...prevDetails,
            address: {
                ...prevDetails.address,
                city: newCity
            }
        }));
    };

    const updatezipCode = (newzipCode) => {
        setCompanyDetails((prevDetails) => ({
            ...prevDetails,
            address: {
                ...prevDetails.address,
                postal_code: newzipCode
            }
        }));
    };

    const updateCoupon = (newCoupon) => {
        setCompanyDetails((prevDetails) => ({
            ...prevDetails,
            coupon: newCoupon
        }));
    };

    const updateVATNumber = (newVatNumber) => {
        if (newVatNumber === '') {
            const { tax_id_data, ...updatedCompanyDetails } = companyDetails;
            setCompanyDetails(updatedCompanyDetails);
        } else {
            const updatedTaxIdData = [{
                type: 'eu_vat',
                value: newVatNumber
            }];

            setCompanyDetails(prevCompanyDetails => ({
                ...prevCompanyDetails,
                tax_id_data: updatedTaxIdData
            }));
        }
    };

    const validateData = () => {
        var isValid = true;
        var errors = {};

        const country = companyDetails.address.country.trim();
        const countryCode = availableCountries.find((c) => c.name === country);
        if (!country || !countryCode) {
            isValid = false;
            errors.country = "Please enter a valid country.";
        }

        if (!userName.trim()) {
            isValid = false;
            errors.name = "Please enter a valid name";
        }

        if (!userSurname.trim()) {
            isValid = false;
            errors.name = "Please enter a valid surname";
        }

        if (!companyDetails.address.line1.trim()) {
            isValid = false;
            errors.billingAddress = "Please enter a valid billing address.";
        }

        if (!companyDetails.address.city.trim()) {
            isValid = false;
            errors.city = "Please enter a valid city.";
        }

        if (!companyDetails.address.postal_code.trim()) {
            isValid = false;
            errors.zipCode = "Please enter a valid ZIP code.";
        }

        const vatNumber = companyDetails.tax_id_data?.[0]?.value || '';

        if (vatNumber.length > 0 && (vatNumber && !/^[a-zA-Z]{2}[0-9]+$/.test(vatNumber))) {
            isValid = false;
            errors.vatNumber = "Please enter a valid VAT number (e.g., LV40203159237).";
        }

        setErrors(errors);

        return isValid;
    }

    const handleSubmit = async (event) => {

        event.preventDefault();

        if (!validateData()) {
            return;
        }

        const adjustedCompanyDetails = JSON.parse(JSON.stringify(companyDetails));


        if (companyName && companyName.length > 0) {
            adjustedCompanyDetails.name = companyName;
            adjustedCompanyDetails.metadata = {
                customer_type: "business",
            };
        } else {
            adjustedCompanyDetails.metadata = {};
            delete adjustedCompanyDetails.tax_id_data;
            adjustedCompanyDetails.name = userName + " " + userSurname;
            adjustedCompanyDetails.metadata = {
                customer_type: "private",
            };
        }

        const countryName = adjustedCompanyDetails.address.country.trim()
        const countryCode = availableCountries.find((c) => c.name === countryName);

        if (countryCode) {
            adjustedCompanyDetails.address.country = countryCode.code
        }

        if (requirePayment) {
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: "card",
                card: elements.getElement(CardElement)
            });

            if (error) {
                const message = error.message;
                window.alert(message);
            } else {
                const paymentMethodId = paymentMethod.id;
                const result = await onCompanyDetailsReady({ paymentMethodId, companyDetails: adjustedCompanyDetails })
                if (result == undefined) {
                    onFailed();
                    return
                }
                if (result.requires_action) {
                    await perform3Dsecure(result, paymentMethodId);
                } else {
                    onComplete();
                }
            }
        } else {
            onCompanyDetailsReady(adjustedCompanyDetails)
        }
    };

    const perform3Dsecure = async (result, paymentMethodId) => {
        const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(result.client_secret, {
            payment_method: paymentMethodId,
        });

        if (confirmError) {
            alert(`Payment failed: ${confirmError.message}`);
            onFailed();
            return;
        }

        if (paymentIntent && paymentIntent.status === 'succeeded') {
            onComplete();
        }
    }

    return (
        <form onSubmit={handleSubmit} className="form">
            {
                requirePayment && (
                    <div>
                        <label className="form-label">
                            Card Number:
                        </label>
                        <div className="form-group-card">
                            <CardElement options={{ hidePostalCode: true }} />
                        </div>
                    </div>
                )
            }
            <div className="form-group-company">
                <label className="form-label" style={{ color: trainerColors?.textSecondaryColor }}>
                    Company name (optional):
                    <input
                        type="text"
                        value={companyName}
                        onChange={(e) => updateCompanyName(e.target.value)}
                        className={`form-input ${errors.companyName ? "has-error" : ""}`}
                    />
                    {errors.companyName && <span className="form-error form-error-red">{errors.companyName}</span>}
                </label>
                <div className="form-group">
                    <label className="form-label" style={{ color: trainerColors?.textSecondaryColor }}>
                        VAT Number (optional):
                        <input
                            type="text"
                            value={companyDetails.tax_id_data?.[0]?.value || ''}
                            onChange={(e) => updateVATNumber(e.target.value)}
                            className={`form-input ${errors.vatNumber ? "has-error" : ""}`}
                        />
                        {errors.vatNumber && <span className="form-error form-error-red">{errors.vatNumber}</span>}
                    </label>
                </div>
            </div>
            <div className="form-group">
                {
                    editName && (
                        <div className="form-group">
                            <label className="form-label" style={{ color: trainerColors?.textSecondaryColor }}>
                                Name
                                <input
                                    type="text"
                                    value={userName}
                                    onChange={(e) => setUserName(e.target.value)}
                                    className={`form-input ${errors.name ? "has-error" : ""}`}
                                    disabled
                                />
                                {errors.name && <span className="form-error form-error-red">{errors.name}</span>}
                            </label>
                            <label className="form-label" style={{ color: trainerColors?.textSecondaryColor }}>
                                Surname
                                <input
                                    type="text"
                                    value={userSurname}
                                    onChange={(e) => setUserSurname(e.target.value)}
                                    className={`form-input ${errors.surname ? "has-error" : ""}`}
                                    disabled
                                />
                                {errors.surname && <span className="form-error form-error-red">{errors.surname}</span>}
                            </label>
                        </div>
                    )
                }
                <label className="form-label" style={{ color: trainerColors?.textSecondaryColor }}>
                    Country:
                    <input
                        type="text"
                        value={companyDetails.address.country}
                        onChange={(e) => updateCountry(e.target.value)}
                        className={`form-input ${errors.country ? "has-error" : ""}`}
                        list="addressSuggestions"
                    />
                    {errors.country && <span className="form-error form-error-red">{errors.country}</span>}
                </label>
                <datalist id="addressSuggestions">
                    {availableCountries.map((country, index) => (
                        <option key={index} value={country.name} />
                    ))}
                </datalist>
            </div>
            <div className="form-group">
                <label className="form-label" style={{ color: trainerColors?.textSecondaryColor }}>
                    City:
                    <input
                        type="text"
                        value={companyDetails.address.city}
                        onChange={(e) => updateCity(e.target.value)}
                        className={`form-input ${errors.city ? "has-error" : ""}`}
                    />
                    {errors.city && <span className="form-error form-error-red">{errors.city}</span>}
                </label>
            </div>
            <div className="form-group">
                <label className="form-label" style={{ color: trainerColors?.textSecondaryColor }}>
                    Billing Address:
                    <input
                        type="text"
                        value={companyDetails.address.line1}
                        onChange={(e) => updateAddress(e.target.value)}
                        className={`form-input ${errors.billingAddress ? "has-error" : ""}`}
                    />
                    {errors.billingAddress && <span className="form-error form-error-red">{errors.billingAddress}</span>}
                </label>
            </div>
            <div className="form-group">
                <label className="form-label" style={{ color: trainerColors?.textSecondaryColor }}>
                    ZIP Code:
                    <input
                        type="text"
                        value={companyDetails.address.postal_code}
                        onChange={(e) => updatezipCode(e.target.value)}
                        className={`form-input ${errors.zipCode ? "has-error" : ""}`}
                    />
                    {errors.zipCode && <span className="form-error form-error-red">{errors.zipCode}</span>}
                </label>
            </div>
            {showCoupon && (
                <div className="form-group">
                    <label className="form-label">
                        Coupon code (optional)
                        <input
                            type="text"
                            value={companyDetails.coupon}
                            onChange={(e) => updateCoupon(e.target.value)}
                            className={`form-input ${errors.zipCode ? "has-error" : ""}`}
                        />
                    </label>
                </div>)
            }
            <div className="form-btn">
                <BlueButton
                    type="submit"
                    style={{ backgroundColor: trainerColors?.accentColor }}
                >
                    {submitTitle}
                </BlueButton>
            </div>
        </form>

    );
};

export default CompanyDetailsForm;