import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FORGOT_PASSWORD_URI } from 'src/contants';

import { useNavigate } from 'react-router-dom';
import { NetworkProvider } from 'src/NetworkProvider';
import { AuthContext } from 'src/AuthProvider';
import FullScreenLoader from 'src/Elements/FullScreenLoader';

import { UserTextFields } from '../UserTextfields/UserTextFields.jsx';
import { OnboardingState } from '../Enums';
import OnboardingPrompt from 'src/Elements/OnboardingPrompt';

import usePaymentStyles from '../PaymentOnboarding/PaymentStyles';
import colors from 'src/colors.json';

import { CREATE_ACCOUNT_URI, COMPANY_SETUP_URI, ADMIN_URI } from 'src/contants';

import generateContractPDF from 'src/Utilities/PDFEditor.jsx'


function SignIn() {

    const classes = usePaymentStyles();
    const navigate = useNavigate();
    const [loadingText, setLoadingText] = useState(null);
    const { setLoginCredentials } = useContext(AuthContext);

    const logoPath = require(`src/Assets/Logos/appscribe.png`);
    const trainerColors = colors['default']

    async function handleSignIn(formData, captchaToken) {

        if (captchaToken == null) {
            alert("Failed to validate captcha")
            return;
        }

        try {
            await NetworkProvider.validateCaptchaToken(captchaToken)
        } catch (error) {
            alert("Failed to validate captcha ", error)
            return
        }

        const { email, password } = formData;

        try {
            setLoadingText("Loging in")
            const tokenData = await NetworkProvider.acquire_token(email, password);
            const { access_token, author_id } = tokenData;

            if (access_token == null) {
                setLoadingText(null)
                window.alert("Wrong credentials provided");
                return;
            }

            const credentials = {
                authToken: access_token,
                authorId: author_id,
                email,
                password
            }

            setLoginCredentials(access_token, author_id, null, email, password)

            try {
                const authorData = await NetworkProvider.get_author(credentials, setLoginCredentials);
                const stateToPass = {
                    ...tokenData,
                    authorData
                };

                const subscriptionInfo = await NetworkProvider.get_author_sub_info(credentials, setLoginCredentials);

                if (subscriptionInfo.active == true) {
                    if (authorData && authorData.contract == null && authorData.name && authorData.mail) {
                        setLoadingText("Generating contract")
                        const contractFile = await generateContractPDF(authorData.name, authorData.mail)
                        await NetworkProvider.upload_author_contract(credentials, setLoginCredentials, contractFile)
                        setLoadingText("Loging in")
                    }
                    navigate(ADMIN_URI, { state: stateToPass });
                } else {
                    navigate(COMPANY_SETUP_URI, { state: stateToPass });
                }
                setLoadingText(null)
            } catch (error) {
                setLoadingText(null)
                window.alert(error.detail || error);
            }
        } catch (error) {
            setLoadingText(null)
            window.alert(error.detail || error);
        }
    }


    function handleSignup() {
        navigate(CREATE_ACCOUNT_URI);
    }

    function handleForgotPassword() {
        navigate(FORGOT_PASSWORD_URI);
    }

    function onFailedValidatingCaptcha(error) {
        setLoadingText(null);
        alert("Failed to validate captcha" + error)
    }

    return (
        <div className={classes.rootSquares}>
            <Helmet>
                <script type="application/ld+json">
                    {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "Onboarding",
              "description": "Introduce Artificial intelligence power. Stay ahead of your competition with the best customer service 24/7.",
              "url": "${window.location.href}"
            }
          `}
                </script>
            </Helmet>
            <div className={classes.thirdShape}></div>
            <div className={classes.cardContainer}>
                <div className={classes.logoContainer}>
                    <img src={logoPath} alt="Logo" className={classes.logo} />
                    <span className={classes.logoText}>{"Appscribe"}</span>
                </div>
                <div
                    className={classes.card}
                    style={{
                        backgroundColor: trainerColors?.primaryColor,
                        color: trainerColors?.textPrimaryColor
                    }}
                >
                    <div className={classes.titleText}>Sign in</div>
                    <UserTextFields
                        onboardingState={OnboardingState.SIGN_IN}
                        onSubmit={handleSignIn}
                        onValidatingCaptcha={() => setLoadingText('Validating captcha')}
                        onFailedValidatingCaptcha={onFailedValidatingCaptcha}
                        trainerColors={trainerColors}
                    />
                    <OnboardingPrompt
                        onClick={handleSignup}
                        onboardingState={OnboardingState.CREATE_ACCOUNT}
                        trainerColors={trainerColors}
                    />
                    <OnboardingPrompt
                        onClick={handleForgotPassword}
                        onboardingState={OnboardingState.FORGOT_PASSWORD}
                        trainerColors={trainerColors}
                    />
                </div>
            </div>
            <FullScreenLoader loadingText={loadingText} />
        </div>
    );
}

export default SignIn;