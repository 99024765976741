import { makeStyles } from '@mui/styles';

const usePaymentSubscriptionStyles = makeStyles({
    subscriptionCard: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start", 
        maxWidth: 350,
        padding: "20px",
        marginTop: '20px',
        textAlign: "center",
        border: "1px solid #ddd",
        borderRadius: 12,
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#fff",
    },
    renewText: {
        fontSize: "0.875rem",
        color: "#666",
        marginBottom: "20px",
    },
    ghostWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "20px",
    },
    ghostIcon: {
        fontSize: "3rem",
        backgroundColor: "#f4f4f4",
        borderRadius: "50%",
        padding: "10px",
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "space-evenly",
        marginTop: "20px",
    },
    actionButton: {
        padding: "10px 20px",
        fontSize: "0.875rem",
        "&:not(:last-child)": {
            marginRight: "10px",
        },
    },
    buttonContainer: {
        display: "flex",
        gap: "5px",
        height: 36
    },
});

export default usePaymentSubscriptionStyles;
