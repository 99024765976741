import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BackButton from 'src/Elements/BackButton';

import Plans from '../Categories/TrainingPlans/Plans';
import CategoriesReducer from 'src/Utilities/CategoriesReducer';
import { NetworkProvider } from 'src/NetworkProvider';
import { AuthContext } from 'src/AuthProvider';

import { generateUniqueId } from 'src/Utilities/Utilities';

const StateEnum = {
    NONE: -1,
    ADD_WORKOUT_PLAN: 0,
    ADD_EDIT_PLAN: 1,
    LIST_PLANS: 2,
};

const VipPlans = ({
    vipcode,
    onClose,
    onPlanClick,
    onVipCodeUpdated,
    setLoadingText
}) => {

    const classes = useStyles();
    const [state, setState] = useState(StateEnum.NONE);
    const [editPlan, setEditPlan] = useState(null);
    const { loginCredentials, setLoginCredentials } = useContext(AuthContext);

    const [vipCategory, setVipCategory] = useState(null);

    const initialCategory = {
        "id": generateUniqueId(),
        "authorId": loginCredentials.authorId,
        "name": "Training plans",
        "plans": []
    }

    useEffect(() => {
        const categories = vipcode.categories

        if (categories) {
            try {
                let parsedCategories = JSON.parse(vipcode.categories);
                if (parsedCategories.length > 0) {
                    setVipCategory(parsedCategories[0])
                } else {
                    throw new Error("Failed to show category")
                }
            } catch (error) {
                console.error('Error parsing JSON:', error);
            }
            setState(StateEnum.LIST_PLANS);
        } else {
            setVipCategory(initialCategory);
            setState(StateEnum.ADD_WORKOUT_PLAN)
        }
    }, [vipcode]);

    const handleAddWorkoutPlanClick = () => {
        setState(StateEnum.ADD_EDIT_PLAN);
    }

    const onPlanAdded = async (name, description, type, difficulty, duration, image) => {
        try {
            let imageUrl = '';
            if (image != null) {
                setLoadingText("Uploading image...")
                imageUrl = await NetworkProvider.upload_image(loginCredentials, setLoginCredentials, image);
            }

            const planData = {
                "id": generateUniqueId(),
                "categoryId": vipCategory.id,
                difficulty,
                description,
                "image": imageUrl,
                name,
                type,
                duration
            }

            planData.weeks = planData.weeks || [];
            const updatedCategory = {
                ...vipCategory,
                plans: [...vipCategory.plans, planData]
            };
            onVipCodeUpdated(vipcode, updatedCategory);
            setVipCategory(updatedCategory);
            setState(StateEnum.LIST_PLANS);
            setLoadingText(null)
        } catch (error) {
            window.alert(error);
        }
    }

    const onPlanEdited = async (editedPlan, image) => {
        try {
            let newPlan = editedPlan

            if (image) {
                const oldImage = editPlan.image
                if (oldImage) {
                    try {
                        await NetworkProvider.delete_image(loginCredentials, setLoginCredentials, oldImage)
                    } catch { }
                }
                const imageUrl = await NetworkProvider.upload_image(loginCredentials, setLoginCredentials, image);
                newPlan.image = imageUrl
            }

            const updatedPlans = vipCategory.plans.map((plan) =>
                plan.id === editedPlan.id ? editedPlan : plan
            );

            const updatedCategory = { ...vipCategory, plans: updatedPlans };
            onVipCodeUpdated(vipcode, updatedCategory)
            setVipCategory(updatedCategory);
            setState(StateEnum.LIST_PLANS);
        } catch (error) {
            alert(error)
        }
    };

    const onPlanDeleteClick = async (plan) => {
        try {
            await CategoriesReducer.deletePlan(loginCredentials, setLoginCredentials, plan)
            const updatedPlans = vipCategory.plans.filter((p) => p.id !== plan.id);
            const updatedCategory = { ...vipCategory, plans: updatedPlans };
            onVipCodeUpdated(vipcode, updatedCategory);
            setVipCategory(updatedCategory);
        } catch (error) {
            alert(error);
        }
    }


    const onPlanEditClick = (plan) => {
        setEditPlan(plan);
        setState(StateEnum.ADD_EDIT_PLAN);
    }

    const onAddWorkoutPlanClick = () => {
        setState(StateEnum.ADD_EDIT_PLAN);
    }

    return (
        <div>
            <BackButton onClick={onClose} />
            <h2 className={classes.mainTitle}>
                {vipcode.mail ? vipcode.mail : vipcode.code + " -> " + "Plans"}
            </h2>
            <div className={classes.contentArea}>
                {vipCategory &&
                    <Plans
                        state={state}
                        StateEnum={StateEnum}
                        localCategory={vipCategory}
                        editPlan={editPlan}
                        onAddWorkoutPlanClick={onAddWorkoutPlanClick}
                        handleAddWorkoutPlanClick={handleAddWorkoutPlanClick}
                        onPlanClick={onPlanClick}
                        onPlanAdded={onPlanAdded}
                        onPlanEdited={onPlanEdited}
                        onPlanDeleteClick={onPlanDeleteClick}
                        onPlanEditClick={onPlanEditClick}
                    />
                }
            </div>
        </div>
    );
};

const useStyles = makeStyles({
    mainTitle: {
        fontSize: 29,
        fontFamily: 'Inter',
        fontWeight: 600,
        color: '#000000',
    },
    contentArea: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    }
});

export default VipPlans;
