import React, { createContext, useContext, useState, useEffect } from 'react';
import CategoriesReducer from 'src/Utilities/CategoriesReducer';
import { NetworkProvider } from 'src/NetworkProvider';
import { AuthContext } from 'src/AuthProvider';
import VipCodesReducer from '../VipTraining/VipCodesReducer';
import { video_thumbnail_url_from_file_name } from 'src/Utilities/Utilities';

// Create a context
export const CategoriesContext = createContext();

// Create a provider component
export const CategoriesProvider = ({ children }) => {
  const [categories, setCategories] = useState([]);
  const [vipCodes, setVipCodes] = useState(null);
  const { loginCredentials, setLoginCredentials } = useContext(AuthContext);

  const [categoryExercises, setCategoryExercises] = useState([]);
  const [vipExercises, setVIPExercises] = useState([]);
  const [exercises, setExercises] = useState([]);

  useEffect(() => {
    if (vipCodes == null) {
      setVIPExercises([])
      return;
    }

    let allExercises = [];

    for (const code of vipCodes) {
      const vipCategories = JSON.parse(code.categories);

      if (vipCategories && vipCategories.length > 0) {
        const vipExercises = CategoriesReducer.getAllExercises(vipCategories);
        allExercises = [...allExercises, ...vipExercises];
      }
    }

    setVIPExercises(allExercises);
  }, [vipCodes]);

  useEffect(() => {
    setCategoryExercises(CategoriesReducer.getAllExercises(categories))
  }, [categories]);

  useEffect(() => {
    const combinedExercises = [...categoryExercises, ...vipExercises];
    setExercises(combinedExercises);
  }, [vipExercises, categoryExercises]);


  const fetchCategories = async () => {
    const categories = await NetworkProvider.get_categories(loginCredentials, setLoginCredentials)
    setCategories(CategoriesReducer.sortedCategories(categories))
  }

  const fetchVipCodes = async () => {
    const codes = await NetworkProvider.get_vip_codes(loginCredentials, setLoginCredentials)
    setVipCodes(VipCodesReducer.sortedCodes(codes))
  };

  const addVIPCode = async (name) => {
    const newCode = await NetworkProvider.insert_vip_code(loginCredentials, setLoginCredentials, name)
    setVipCodes((prevCodes) => [newCode, ...prevCodes]);
  }

  const deleteVIPCode = async (codeToDelete) => {
    await NetworkProvider.delete_vip_code(loginCredentials, setLoginCredentials, codeToDelete.code)
    setVipCodes((prevCodes) => prevCodes.filter((code) => code.code !== codeToDelete.code));
  }

  const updateVIPCodeCategories = async (vipCode, category) => {
    const stringCategories = JSON.stringify([category]);
    await NetworkProvider.update_vipcode_categories(loginCredentials, setLoginCredentials, vipCode, stringCategories);
    setVipCodes((prevVipcodes) => {
      const existingCodeIndex = prevVipcodes.findIndex((code) => code.code === vipCode.code);

      if (existingCodeIndex !== -1) {
        // Copy the vipCode and update its categories
        const updatedVipCode = { ...prevVipcodes[existingCodeIndex], categories: stringCategories };

        // Create a new array with the updated vipCode
        const updatedVipcodes = [...prevVipcodes];
        updatedVipcodes[existingCodeIndex] = updatedVipCode;

        return updatedVipcodes;
      }
    });
  }

  // MARK: Categories

  const addCategoryByName = async (categoryName) => {
    const response = await NetworkProvider.add_category(loginCredentials, setLoginCredentials, categoryName)
    setCategories([...categories, response])
    return response
  }

  const updateCategoryName = async (categoryId, categoryName) => {
    await NetworkProvider.update_category(loginCredentials, setLoginCredentials, categoryId, categoryName)
    setCategories((prevCategories) =>
      prevCategories.map((category) =>
        category.id === categoryId
          ? { ...category, name: categoryName }
          : category
      )
    );
  }

  const updateCategoryPlan = (updatedPlan) => {
    let updatedCategory = null;

    // Process the updated categories and identify the updated category before setting the state
    const newCategories = categories.map((category) => {
      const updatedPlans = category.plans.map((plan) =>
        plan.id === updatedPlan.id ? updatedPlan : plan
      );

      if (category.plans.some((plan) => plan.id === updatedPlan.id)) {
        updatedCategory = {
          ...category,
          plans: updatedPlans,
        };
        return updatedCategory; // This is the updated category
      }

      return {
        ...category,
        plans: updatedPlans,
      };
    });

    // Set the updated categories in state
    setCategories(newCategories);

    // Return the updated category
    return updatedCategory;
  };



  const deleteCategory = async (categoryToDelete) => {
    await Promise.all(categoryToDelete.plans.map(async (plan) => {
      try {
        await CategoriesReducer.deletePlan(loginCredentials, setLoginCredentials, plan);
        await NetworkProvider.delete_plan(loginCredentials, setLoginCredentials, plan.id);
        return
      } catch (error) {
        console.error(`Error deleting plan ${plan.id}:`, error);
        throw error;
      }
    }));
    await NetworkProvider.delete_category(loginCredentials, setLoginCredentials, categoryToDelete.id)
    setCategories((prevCategories) =>
      prevCategories.filter((category) =>
        category.id !== categoryToDelete.id
      )
    );
  }

  const deleteWeek = async (week) => {

    const videosToDelete = week.days.flatMap(day =>
      day.exercises.filter(ex => ex.video)
    );

    const videoCounts = exercises.reduce((counts, ex) => {
      if (ex.video) {
        counts[ex.video] = (counts[ex.video] || 0) + 1;
      }
      return counts;
    }, {});

    const filteredVideosToDelete = videosToDelete.filter(video => videoCounts[video] === 1);

    await Promise.all(filteredVideosToDelete.map(async (video) => {
      try {
        await NetworkProvider.delete_video(loginCredentials, setLoginCredentials, video);
        const thumbnailUrl = video_thumbnail_url_from_file_name(video, loginCredentials.authorId);
        if (thumbnailUrl) {
          await NetworkProvider.delete_image(loginCredentials, setLoginCredentials, thumbnailUrl);
        }
      } catch (error) {
        console.error(`Error deleting video ${video}:`, error);
        throw error;
      }
    }));
  };

  const deleteExercise = async (exercise) => {
    const video = exercise.video;

    // Check if the video appears more than once in the categories or vipcodes exercises
    const videoCount = exercises.filter((ex) => ex.video === video).length;

    if (video != null && videoCount == 1) {
      await NetworkProvider.delete_video(loginCredentials, setLoginCredentials, video);
      const thumbnailUrl = video_thumbnail_url_from_file_name(video, loginCredentials.authorId);

      if (thumbnailUrl) {
        await NetworkProvider.delete_image(loginCredentials, setLoginCredentials, thumbnailUrl)
      }
    }
  }

  return (
    <CategoriesContext.Provider value={{
      categories,
      setCategories,
      exercises,
      vipCodes,
      fetchCategories,
      addCategoryByName,
      updateCategoryName,
      updateCategoryPlan,
      deleteCategory,
      fetchVipCodes,
      addVIPCode,
      updateVIPCodeCategories,
      deleteVIPCode,
      deleteExercise,
      deleteWeek
    }}>
      {children}
    </CategoriesContext.Provider>
  );
};