import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from 'src/AuthProvider';
import CreateAccountForTrainer from '../Onboarding/PaymentOnboarding/CreateAccountForTrainer';
import SignInForTrainer from '../Onboarding/PaymentOnboarding/SignInForTrainer';
import ResetPasswordForTrainer from '../Onboarding/PaymentOnboarding/ResetPasswordForTrainer';
import AdminForTrainer from '../Onboarding/PaymentOnboarding/AdminForTrainer/AdminForTrainer';

import { CREATE_ACCOUNT_URI, 
  SIGN_IN_URI, 
  FORGOT_PASSWORD_URI, 
  RESET_PASSWORD_URI, 
  ADMIN_URI,
  USER_PAYMENT_SUCCESS_URI } from 'src/contants';

function ProtectedRoute({ children }) {
  const { loginCredentials } = useContext(AuthContext);
  const location = useLocation();
  const sourcePath = location.pathname;


  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const user_id = queryParams.get('u');
  const trainer = queryParams.get('trainer');


  if ((!loginCredentials.email || !loginCredentials.password) && sourcePath == RESET_PASSWORD_URI) {
    if (token && user_id) {
      return children
    } else {
      return <Navigate to={SIGN_IN_URI} />;
    }
  }

  if ((!loginCredentials.email || !loginCredentials.password) && (sourcePath == ADMIN_URI)) {
    return <Navigate to={SIGN_IN_URI} />;
  }

  if (sourcePath === CREATE_ACCOUNT_URI && trainer) {
    return <CreateAccountForTrainer/>;
  }

  if (sourcePath.includes(SIGN_IN_URI) && trainer) {
    return <SignInForTrainer/>;
  }

  if (sourcePath.includes(FORGOT_PASSWORD_URI) && trainer) {
    return <ResetPasswordForTrainer/>;
  }

  if (sourcePath.includes(ADMIN_URI) && trainer) {
    return <AdminForTrainer/>;
  }

  if (sourcePath.includes(USER_PAYMENT_SUCCESS_URI) && !trainer) {
    return <Navigate to={SIGN_IN_URI} />;
  }

  return children
}

export default ProtectedRoute;