import React from 'react';
import { makeStyles } from '@mui/styles';

const FocusedOption = ({ title, subtitle, price, currency, isFocused, onFocusChange, id, trainerColors, footerText }) => {
  const classes = useStyles();

  return (
    <div
      className={classes.container}
      style={{
        border: isFocused
          ? `1px solid ${trainerColors?.accentColor || '#3F6EE8'}`
          : `1px solid rgba(128, 128, 128, 0.1)`,
        boxShadow: isFocused ? '0 0 10px rgba(76, 110, 245, 0.2)' : 'none',
      }}
      onClick={() => onFocusChange(title, id)}
      role="button"
      tabIndex={0}
    >
      <div className={classes.radioButton}>
        <div
          className={classes.outerCircle}
          style={{
            border: `2px solid ${trainerColors?.accentColor || '#3F6EE8'}`,
            opacity: isFocused ? 1 : 0.3,
          }}
        >
          {isFocused && (
            <div
              className={classes.innerCircle}
              style={{ backgroundColor: trainerColors?.accentColor || '#3F6EE8' }}
            />
          )}
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.title}>{title}</div>
        <div className={classes.subtitle}>{subtitle}</div>
        <div className={classes.priceWrapper}>
          <span className={classes.price}>{price}</span>
          <span className={classes.currency}>{currency}</span>
        </div>
      </div>
      {footerText && <div className={classes.footerLabel}>{footerText}</div>}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '16px',
    borderRadius: '6px',
    backgroundColor: 'white',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    '&:hover': {
      boxShadow: '0 0 8px rgba(0, 0, 0, 0.1)',
    },
  },
  content: {
    paddingRight: '40px',
  },
  title: {
    fontSize: '16px',
    fontWeight: '600',
    color: '#1F2937',
    marginBottom: '4px',
  },
  subtitle: {
    fontSize: '14px',
    color: '#6B7280',
    marginBottom: '12px',
  },
  priceWrapper: {
    display: 'flex',
    alignItems: 'baseline', // Aligns price and currency properly
  },
  price: {
    fontSize: '20px',
    fontWeight: '600',
    color: '#1F2937',
  },
  currency: {
    fontSize: '12px',
    marginLeft: '4px',
    color: '#6B7280',
  },
  radioButton: {
    position: 'absolute',
    top: '16px',
    right: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  outerCircle: {
    width: '21px',
    height: '21px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  innerCircle: {
    width: '19px',
    height: '19px',
    borderRadius: '50%',
  },
  footerLabel: {
    position: 'absolute',
    bottom: '8px',
    right: '8px',
    fontSize: '12px',
    color: '#6B7280', // Muted gray for the label text
    backgroundColor: '#F3F4F6', // Light gray background for contrast
    padding: '4px 8px',
    borderRadius: '4px',
  },
}));

export default FocusedOption;
