import React, { useEffect, useContext, useState } from "react";
import { NetworkProvider } from "src/NetworkProvider";
import { AuthContext } from "src/AuthProvider";
import colors from 'src/colors.json';
import BlueButton from "src/Elements/BlueButton";
import { useNavigate } from 'react-router-dom';
import { SUB_URI } from 'src/contants';
import { Typography } from "@mui/material";
import DeleteConfirmDialog from "src/Utilities/Popups/DeleteConfirmDialog";
import usePaymentSubscriptionStyles from "./PaymentSubscriptionStyles";
import { Button, Card, CardContent } from "@mui/material";

const PaymentSubscriptionInfo = ({ trainer }) => {
  const navigate = useNavigate();
  const classes = usePaymentSubscriptionStyles();
  const [subscriptionInfo, setSubscriptionInfo] = useState(null);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(null);
  const { loginCredentials, setLoginCredentials } = useContext(AuthContext);

  const [tryingToUnsubscribe, setTryingToUnsubscribe] = useState(false);

  const renewalDate = subscriptionInfo?.renewal_date;

  const formattedRenewalDate = renewalDate
    ? new Date(renewalDate * 1000).toLocaleDateString("en-GB") // Format as "dd/MM/yyyy"
    : "N/A";

  const trainerColors = colors[trainer] || colors['default']

  const fetchSubscription = async () => {
    try {
      const subscriptionInfo = await NetworkProvider.get_user_sub_info(loginCredentials, setLoginCredentials, trainer);
      setSubscriptionInfo(subscriptionInfo)
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    fetchSubscription();
  }, [trainer]);

  const navigateToPrices = () => {
    let subUri = SUB_URI + "/?trainer=" + trainer
    navigate(subUri);
  }

  const handleCancelSubscription = async () => {
    if (!subscriptionInfo.subscription_id) {
      return
    }

    try {
      const result = NetworkProvider.cancel_subscription(loginCredentials, setLoginCredentials, trainer, subscriptionInfo.subscription_id)
      setTryingToUnsubscribe(false)
      if (result) {
        window.alert("Subscription canceled")
        fetchSubscription();
      } else {
        window.alert("Cancelation failed. Reach out to developer@appscribe.io to continue cancelation")
      }
    } catch (error) {
      window.alert(error)
    }

  };

  return (
    <>
      {subscriptionInfo && subscriptionInfo.active &&
        <Card className={classes.subscriptionCard}>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start"
            }}
          >
            <Typography variant="h10" component="p">
              Current <strong>subscription plan</strong>
            </Typography>
            <div>
              <Typography component="span" sx={{ fontSize: "44px", fontWeight: 700 }}>
                {Math.floor(subscriptionInfo.price.amount)}
              </Typography>
              <Typography component="span" sx={{ fontSize: "20px", fontWeight: 600 }}>
                .{(subscriptionInfo.price.amount % 1).toFixed(2).split(".")[1]}
              </Typography>
              <Typography component="span" sx={{ fontSize: "20px", fontWeight: 600 }}>
                {subscriptionInfo.price.currency.toUpperCase()}
              </Typography>
            </div>

            <Typography className={classes.renewText}>{"Will renew at " + formattedRenewalDate}</Typography>
            <div className={classes.buttonContainer}>
              <BlueButton
                style={{ fontSize: '12px' }}
                className={classes.actionButton}
                onClick={navigateToPrices}
                sx={{
                  width: '91px !important',
                  backgroundColor: trainerColors?.accentColor,
                  color: trainerColors?.textPrimaryColor, // White text
                  textTransform: "none", // Preserve text case
                  borderRadius: "var(--borderRadius, 4px)",
                  fontWeight: "500", // Slightly bold text
                  fontSize: "0.875rem", // Font size
                  padding: "8px 16px",
                }}
              >
                Change
              </BlueButton>
              {subscriptionInfo.active && (
                <Button
                  style={{ fontSize: '12px' }}
                  variant="outlined"
                  color="secondary"
                  className={classes.actionButton}
                  onClick={() => setTryingToUnsubscribe(true)}
                  sx={{
                    display: "inline-flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "8px 22px",
                    borderRadius: "var(--borderRadius, 4px)",
                    border: "1px solid var(--text-primary, rgba(0, 0, 0, 0.87))",
                    borderColor: "#2c2c2c",
                    color: "#2c2c2c",
                    textTransform: "none",
                    fontWeight: "500",
                    "&:hover": {
                      borderColor: "#000",
                      color: "#000"
                    },
                  }}
                >
                  Cancel Subscription
                </Button>
              )}
            </div>
          </CardContent>
        </Card>
      }
      {subscriptionInfo && !subscriptionInfo.active && (
        <BlueButton onClick={navigateToPrices}>
          Activate subscription
        </BlueButton>
      )}
      <DeleteConfirmDialog
        open={tryingToUnsubscribe}
        title={"Are you sure you want to cancel your active subscription"}
        onConfirm={handleCancelSubscription}
        onCancel={() => setTryingToUnsubscribe(false)}
      />
    </>
  );
};

export default PaymentSubscriptionInfo;
