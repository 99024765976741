import React, { useState, useEffect, useRef, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import WhiteButton from 'src/Elements/WhiteButton';
import { Container } from '@material-ui/core';
import WhiteTextField from 'src/Elements/WhiteTextField';
import BlueTransparentButton from 'src/Elements/BlueTransparentButton';
import BlueButton from 'src/Elements/BlueButton';
import ImagePreview from 'src/Elements/ImagePreview';
import DeleteButton from 'src/Elements/Deletebutton';
import { AuthContext } from 'src/AuthProvider';
import { NetworkProvider } from 'src/NetworkProvider';

import ExerciseVideoThumbnail from '../Categories/PlanCalendar/Exercises/ExerciseVideoThumbnail';
import { MAX_DAILY_VIDEO_SIZE_MB, MAX_DAILY_VIDEO_DURATION_SECONDS } from 'src/contants';
import { createThumbnail } from 'src/Utilities/Utilities';

const CookingMethods = ({ recipe, onDoneClick, setLoadingText }) => {
    const classes = useStyles();
    const [localMethods, setLocalMethods] = useState(recipe.methods);
    const { loginCredentials, setLoginCredentials } = useContext(AuthContext);

    const fileInputRefs = useRef([]);
    const videoFileRefs = useRef([]);

    const [thumbnails, setThumbnails] = useState({});

    const addCookingMethod = async () => {
        const newCookingMethod = {
            index: localMethods.length,
            description: '',
        };
        setLocalMethods([...localMethods, newCookingMethod]);
    };

    const deleteMethodImage = async (method) => {
        const oldImage = method.image;
        if (oldImage) {
            try {
                await NetworkProvider.delete_image(loginCredentials, setLoginCredentials, oldImage);
            } catch (error) {
                console.error("Error deleting old image", error);
            }
        }
    }

    const handleImageUpload = async (imageFile, method, index) => {
        setLoadingText("Deleting image");
        await deleteMethodImage(method)

        if (method.video) {
            setLoadingText("Deleting video");
            try {
               await NetworkProvider.delete_video(loginCredentials, setLoginCredentials, method.video)
            } catch { }
        }

        try {
            setLoadingText("Uploading new image");
            const newImageUrl = await NetworkProvider.upload_image(loginCredentials, setLoginCredentials, imageFile);

            setLocalMethods((prevMethods) => {
                const updatedMethods = [...prevMethods];
                updatedMethods[index] = {
                    ...updatedMethods[index],
                    image: newImageUrl,
                    video: null
                };
                return updatedMethods;
            });

            setLoadingText(null);
        } catch (error) {
            console.error("Error uploading new image", error);
            setLoadingText(null);
        }
    };

    const handleMethodDescriptionChange = (index, newDescription) => {
        const updatedMethods = [...localMethods];
        updatedMethods[index].description = newDescription;
        setLocalMethods(updatedMethods);
    };

    const onDeleteMethod = async (index) => {
        try {
            const updatedMethods = [...localMethods];
            updatedMethods.splice(index, 1);
            setLocalMethods(updatedMethods);
        } catch (error) {
            alert(error);
        }
    };

    const renderVideoThumbnail = (method, index) => {
        if (thumbnails[index]) {
            return (
                <div className={classes.thumbnailContainer}>
                    <ImagePreview
                        src={thumbnails[index]}
                        alt={"Preview image"}
                        className={classes.imagePreview}
                    />
                </div>
            );
        } else if (method.video) {
            return (
                <div className={classes.thumbnailContainer}>
                    <ExerciseVideoThumbnail
                        key={method.video}
                        authorId={loginCredentials.authorId}
                        fileName={method.video}
                    />
                </div>
            );
        } else {
            return null;
        }
    };

    const handleVideoUpload = async (videoFile, index) => {

        // Check size
        const maxSizeBytes = MAX_DAILY_VIDEO_SIZE_MB * 1024 * 1024;
        if (videoFile.size > maxSizeBytes) {
            alert("The selected video exceeds the maximum size limit of " + MAX_DAILY_VIDEO_SIZE_MB + "MB");
            return;
        }

        // Check duration by loading metadata
        const video = document.createElement('video');
        video.preload = 'metadata';
        video.src = URL.createObjectURL(videoFile);
        document.body.appendChild(video);
        const metadataLoaded = new Promise((resolve) => {
            video.onloadedmetadata = () => resolve();
        });
        await metadataLoaded;
        if (Math.ceil(video.duration) > MAX_DAILY_VIDEO_DURATION_SECONDS) {
            alert("The selected video exceeds the maximum duration limit of " + MAX_DAILY_VIDEO_DURATION_SECONDS + " seconds");
            return;
        }

        uploadVideo(videoFile, index);
        await createThumbnail(videoFile, "local", (thumbnailFile) => {
            const thumbnailBlob = URL.createObjectURL(thumbnailFile);
            setThumbnails((prevThumbnails) => ({
                ...prevThumbnails,
                [index]: thumbnailBlob
            }));
        });
    };

    const onVideoUploadProgress = (percentage) => {
        setLoadingText("Uploading video " + percentage +"%")
    }

    const uploadVideo = async (videoFile, index) => {
        try {
            const videoResponse = await NetworkProvider.upload_video(loginCredentials, setLoginCredentials, videoFile, onVideoUploadProgress);
        
            const method = localMethods[index];
            await deleteMethodImage(method);
        
            setLocalMethods((prevMethods) => {
                const updatedMethods = [...prevMethods];
                updatedMethods[index] = {
                    ...updatedMethods[index],
                    video: videoResponse,
                    image: null,
                };
    
                return updatedMethods;
            });
            setLoadingText(null);
        } catch (error) {
            setLoadingText(null);
            alert(error);
        }
    }

    const onUploadVideoClick = (index) => {
        videoFileRefs.current[index].current.click();
    };

    const methodCell = (method, index) => {
        // Create a new ref for each input field
        if (!fileInputRefs.current[index]) {
            fileInputRefs.current[index] = React.createRef();
        }

        if (!videoFileRefs.current[index]) {
            videoFileRefs.current[index] = React.createRef();
        }

        const onUploadImageClick = () => {
            fileInputRefs.current[index].current.click();
        };

        return (
            <div key={method.index} className={classes.exerciseLineContent}>
                <div className={classes.excerciseFieldTitle}>{"Instruction description"}</div>
                <WhiteTextField
                    fullWidth
                    multiline={true}
                    value={method.description}
                    placeholder={"Enter description"}
                    onChange={(e) => handleMethodDescriptionChange(index, e.target.value)}
                />
                <div className={classes.videoContainer}>
                    <Container className={classes.imageUploadContainer}>
                        {method?.image && (
                            <div className={classes.thumbnailContainer}>
                                <ImagePreview
                                    src={method.image}
                                    alt={"daily video image"}
                                    className={classes.imagePreview}
                                />
                            </div>
                        )}
                        <p className={classes.imageUploadTitle}>Image preview</p>
                        <div className={classes.uploadButtonContainer}>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={(e) =>
                                    handleImageUpload(e.target.files[0], method, index)
                                }
                                ref={fileInputRefs.current[index]}
                                style={{ display: 'none' }}
                            />
                            <WhiteButton onClick={onUploadImageClick}>Upload image</WhiteButton>
                        </div>
                    </Container>
                    <div style={{ textAlign: 'center', margin: '10px 0' }}>OR</div>
                    <Container className={classes.imageUploadContainer}>
                        {renderVideoThumbnail(method, index)}
                        <p className={classes.imageUploadTitle}>Daily video preview</p>
                        <div className={classes.uploadButtonContainer}>
                            <input
                                type="file"
                                accept="video/*"
                                onChange={(e) =>
                                    handleVideoUpload(e.target.files[0], index)
                                }
                                ref={videoFileRefs.current[index]}
                                style={{ display: 'none' }}
                            />
                            <WhiteButton onClick={() => onUploadVideoClick(index)}>Upload video</WhiteButton>
                        </div>
                    </Container>
                    <div className={classes.deleteWrapper}>
                        <div className={classes.deleteContainer}>
                            <DeleteButton onClick={() => onDeleteMethod(index)} />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const bottomButtonContainer = () => (
        <div className={classes.bottomButtonWrapper}>
            <BlueTransparentButton onClick={addCookingMethod}>Add Cooking method</BlueTransparentButton>
        </div>
    );

    const doneButtonContainer = () => (
        <div className={classes.doneButtonContainer}>
            <BlueButton onClick={() => onDoneClick(localMethods, true)}>Done</BlueButton>
        </div>
    );

    return (
        <div className={classes.container}>
            {localMethods.length === 0 && bottomButtonContainer()}
            {localMethods.map((method, index) => (
                <div key={method.index} className={classes.exerciseContainer}>
                    {methodCell(method, index)}
                    {index === localMethods.length - 1 && bottomButtonContainer()}
                </div>
            ))}
            {doneButtonContainer()}
        </div>
    );
};

const useStyles = makeStyles(() => ({
    container: {
        backgroundColor: '#E4E9ED'
    },
    exerciseContainer: {
        borderRadius: '4px',
        margin: '10px',
        paddingBottom: '10px'
    },
    exerciseLineContent: {
        borderLeft: '2px solid #9CB6FB',
        padding: '10px',
        margin: '20px',
    },
    excerciseFieldTitle: {
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: '14px',
        color: '#425466',
        marginBottom: '3px'
    },
    spacer: {
        height: '20px'
    },
    videoUploadContainer: {
        marginTop: 10,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: 'white',
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        padding: 20
    },
    restTitle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        width: '100%'
    },
    restContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: 'white',
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        height: '90px'
    },
    imageUploadTitle: {
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: '16px',
        color: '#16192C',
        paddingLeft: 10
    },
    uploadButtonContainer: {
        width: '100px'
    },
    videoContainer: {
        marginTop: '10px'
    },
    bottomButtonWrapper: {
        marginTop: '10px',
        marginLeft: '20px',
        marginRight: '20px'
    },
    doneButtonContainer: {
        position: 'sticky',
        bottom: 0,
        zIndex: 1,
        backgroundColor: '#E4E9ED',
        marginLeft: '20px',
        marginRight: '20px',
        paddingTop: '10px',
        paddingBottom: '10px',
        display: 'flex',
        justifyContent: 'center',
    },
    thumbnailContainer: {
        width: 75,
        height: 62,
        borderRadius: '8px',
        overflow: 'hidden',
    },
    deleteWrapper: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'center',
        marginTop: '10px'
    },
    deleteContainer: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '5px',
    },
    deleteIcon: {
        marginRight: 5,
        color: '#DF2E2E'
    },
    deleteText: {
        textAlign: 'center',
        color: '#DF2E2E',
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: '14px',
    },
    imageUploadContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
        backgroundColor: 'white',
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        padding: 20
    },
    imageUploadTitle: {
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: '16px',
        color: '#16192C',
        paddingLeft: 10
    },
    uploadButtonContainer: {
        width: '100px'
    },
    imagePreview: {
        width: '120px',
        height: '80px',
        objectFit: 'cover'
    },
    orLabel: {
        textAlign: 'center', 
        margin: '10px 0'
    }
}));

export default CookingMethods;
