import React from "react";
import { makeStyles } from "@mui/styles";
import image1 from "src/Assets/SuccessAssets/image1.png";
import appleIcon from "src/Assets/SuccessAssets/AppStore.svg";
import googleIcon from "src/Assets/SuccessAssets/GooglePlay.svg";
import trainers from 'src/trainers.json';
import { useSearchParams } from 'react-router-dom';

const UserPaymentSuccess = () => {

    const [searchParams] = useSearchParams();

    const trainer = searchParams.get('trainer');
    const classes = useStyles();
    const trainerInfo = trainers[trainer]
    const trainerName = trainerInfo["name"]
    const appStore = trainerInfo["AppStore"]
    const googlePlay = trainerInfo["GooglePlay"]

    return (
        <div className={classes.container}>
            <div className={classes.content}>
                <div className={classes.header}>
                    <h1 className={classes.title}>Thank you!</h1>
                    <p className={classes.subtitle}>
                        Your purchase is complete. Your full access to the {trainerName} app has been enabled.
                    </p>
                </div>

                <div className={classes.buttonsContainer}>
                    <a
                        href={appStore}
                        className={classes.button}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img className={classes.icon} src={appleIcon} alt="App Store" />
                    </a>
                    <a
                        href={googlePlay}
                        className={classes.button}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img className={classes.icon} src={googleIcon} alt="Google Play" />
                    </a>
                </div>
            </div>
            <div className={classes.bottomImageContainer}>
                <img
                    className={classes.bottomImage}
                    src={image1}
                    alt="Success Background"
                />
            </div>
        </div>
    );
};

const useStyles = makeStyles({
    container: {
        position: "relative",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center", // Centers content vertically
        alignItems: "center", // Centers content horizontally
        backgroundImage: 'url("https://cdn.prod.website-files.com/65da334f1ad89299d3c3f356/671e79fb35e33231696c3c11_Group%20427319726%20(1).svg")',
        backgroundColor: "#4B3CFF", // Fallback color
        backgroundPosition: "center", // Center the image
        backgroundRepeat: "no-repeat", // Prevent image repetition
        backgroundSize: "cover", // Scale image to cover the container
        color: "white",
        fontFamily: "Arial, sans-serif",
        overflow: "hidden", // Ensures no overflow issues
        height: "500px", // Set height to ensure the container displays correctly
    },
    content: {
        zIndex: 1,
        padding: "50px 20px",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        transform: "translateY(-20%)",
    },
    header: {
        marginBottom: "30px",
    },
    title: {
        fontSize: "3rem",
        marginBottom: "10px",
    },
    subtitle: {
        fontSize: "1.2rem",
        marginBottom: "20px",
    },
    buttonsContainer: {
        display: "flex",
        justifyContent: "center",
        gap: "20px",
        marginTop: "20px",
        flexWrap: "wrap", // Ensures buttons wrap on smaller screens
    },
    button: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        textDecoration: "none",
        borderRadius: "10px"
    },
    icon: {
        width: "100%",
        height: "100%",
        transition: "transform 0.2s, box-shadow 0.2s",
        "&:hover": {
            transform: "scale(1.05)",
        },
    },
    buttonText: {
        fontSize: "14px",
        textAlign: "center",
        color: "#000",
    },
    bottomImageContainer: {
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
        zIndex: 0,
    },
    bottomImage: {
        width: "100%",
        height: "auto",
        objectFit: "contain",
        maxHeight: "300px", // Limit image height
    },
    // Media Queries for Responsiveness
    "@media (max-width: 600px)": {
        title: {
            fontSize: "2rem", // Adjust font size for smaller screens
        },
        subtitle: {
            fontSize: "1rem", // Adjust font size for smaller screens
        },
        buttonsContainer: {
            gap: "10px", // Reduce gap between buttons on smaller screens
        },
        button: {
            width: "160px", // Reduce button width on smaller screens
            height: "70px", // Reduce button height on smaller screens
            padding: "10px 15px",
        },
        bottomImage: {
            maxHeight: "200px", // Further limit image height on smaller screens
        },
    },
});

export default UserPaymentSuccess;
