import React, { useContext } from "react";
import { useElements, useStripe, CardElement } from "@stripe/react-stripe-js";
import { NetworkProvider } from '../../NetworkProvider';
import './PaymentsMethodForm.css';
import { AuthContext } from 'src/AuthProvider';
import BlueButton from "src/Elements/BlueButton";


const PaymentMethodForm = ({ onPaymentMethodCreate, setLoadingText, trainer, trainerColors }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { loginCredentials, setLoginCredentials } = useContext(AuthContext);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (error) {
      window.alert(error.message);
    } else {
      try {
        setLoadingText("adding payment method")
        if (trainer) {
          await NetworkProvider.add_user_payment_method(loginCredentials, setLoginCredentials, paymentMethod.id, trainer);
        } else {
          await NetworkProvider.add_payment_method(loginCredentials, setLoginCredentials, paymentMethod.id);
        }
        onPaymentMethodCreate(paymentMethod);
        setLoadingText(null)
      } catch (error) {
        setLoadingText(null)
        window.alert(error);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div
        id="card-element"
        style={{ color: trainerColors?.textSecondaryColor }}
      >
        Card Number:
        <CardElement options={{ hidePostalCode: true }} />
      </div>
      <div className="add-card">
        <BlueButton
          type="submit"
          style={{ backgroundColor: trainerColors?.accentColor }}
        >
          Add Card
        </BlueButton>
      </div>
    </form>
  );
};

export default PaymentMethodForm;