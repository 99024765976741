import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useSpring, animated } from 'react-spring';
import { makeStyles } from '@mui/styles';

const Popup = ({ children, isOpenProp, trainerColors, onClose }) => {
  const classes = useStyles();
  const [isRendered, setIsRendered] = useState(isOpenProp);

  const defaultStyle = { opacity: 0, transform: 'translateY(100vh)' };
  const transitionInStyle = { opacity: 1, transform: 'translateY(0)' };
  const transitionOutStyle = { opacity: 0, transform: 'translateY(200vh)' };

  const { opacity, transform } = useSpring({
    from: defaultStyle,
    to: isOpenProp ? transitionInStyle : transitionOutStyle,
  });

  useEffect(() => {
    if (isOpenProp) {
      setIsRendered(true);
    } else {
      const timeout = setTimeout(() => setIsRendered(false), 300);
      return () => clearTimeout(timeout);
    }
  }, [isOpenProp]);

  return (
    isRendered &&
    ReactDOM.createPortal(
      <div className={classes.popupOverlay}>
        <animated.div
          className={classes.popupContent}
          style={{ opacity, transform, backgroundColor: trainerColors?.primaryColor }}
        >
          <div className={classes.popupChildrenContent}>{children}</div>
          {onClose && (
            <button
              className={classes.popupCloseButton}
              onClick={onClose}
              style={{ color: trainerColors?.textPrimaryColor  }}
            >
              Close
            </button>
          )}
        </animated.div>
      </div>,
      document.body
    )
  );
};

const useStyles = makeStyles({
  popupOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  popupContent: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    backgroundColor: 'white',
    padding: '10px',
    borderRadius: '5px',
    boxSizing: 'border-box',
    maxWidth: '100%',
    maxHeight: 'calc(100vh - 40px)',
    width: 'fit-content',
    overflowY: 'auto', 
  },
  popupChildrenContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '30px',
    width: '100%',
    maxHeight: 'calc(100% - 60px)',
    overflowY: 'auto',
  },
  popupCloseButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    zIndex: 99999999,
    fontSize: '16px',
  },
});

export default Popup;
