import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import useAdminStyles from './AdminStyles.js';
import { SIGN_IN_URI } from 'src/contants';

import { AuthContext } from 'src/AuthProvider';
import FullScreenLoader from 'src/Elements/FullScreenLoader';
import NavigationContext from 'src/Components/Navigation/NavigationContext.jsx';

import PaymentMethods from 'src/Components/Admin/PaymentMethods/PaymentMethods.jsx';
import MyInvoices from 'src/Components/Admin/MyInvoices/MyInvoices.jsx';
import MyCompanyInfo from 'src/Components/Admin/MyCompanyInfo/MyCompanyInfo.jsx';
import PaymentSubscriptionInfo from '../PaymentSubscriptionInfo/PaymentSubscriptionInfo.jsx';
import MenuButton from 'src/Elements/MenuButton';
import colors from 'src/colors.json';
import trainers from 'src/trainers.json';

import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";

import { useSearchParams, useLocation } from 'react-router-dom';


import Popup from 'src/Components/Popup/Popup.jsx';
import ConfirmUnsubscribePopupContent from 'src/Utilities/Popups/ConfirmUnsubscribe/ConfirmUnsubscribePopupContent';

function AdminForTrainer() {

  const navigate = useNavigate();
  const [selected, setSelected] = useState(1);

  const [unsubscribeRequestSubId, setUnsubscribeRequestSubId] = useState(null);
  const [isUnsubscribeOpen, setIsUnsubscribeOpen] = useState(false);

  const [collapsed, setCollapsed] = useState(window.innerWidth < 768);
  const [showContent, setShowContent] = useState(true);
  const { clearCredentials } = useContext(AuthContext);
  const [loadingText, setLoadingText] = useState(null);
  const classes = useAdminStyles();
  const { setOnboardingState } = useContext(NavigationContext);

  const location = useLocation();
  const passedState = location.state;

  const [searchParams] = useSearchParams();

  const trainer = searchParams.get('trainer');
  const trainerColors = colors[trainer] || colors['default']
  const trainerInfo = trainers[trainer]
  const trainerName = trainerInfo["name"]

  let logoPath;

  try {
    logoPath = require(`src/Assets/Logos/${trainer}.png`);
  } catch (error) {
    console.error(`Logo not found for trainer: ${trainer}`, error);
  }


  const handleWindowResize = () => {
    setCollapsed(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const handleItemClick = (item) => {
    setSelected(item);
    if (collapsed) {
      setShowContent(true);
    }
  };

  const handleBackClick = () => {
    setShowContent(false);
  };

  const handleCloseUnsubscribe = () => {
    setUnsubscribeRequestSubId(null);
  };

  const onUnsubscribeRequested = (subscriptionId) => {
    setUnsubscribeRequestSubId(subscriptionId)
  };

  const confirmUnsubscribeCallback = (email, subscriptionId) => {
    /*
    if (!subscriptionId) {
      return
    }

    const validatedEmail = validated_email(email);

    NetworkProvider.cancelSubscription(clientData.token, validatedEmail, subscriptionId)
      .then(data => {
        setUnsubscribeRequestSubId(null);
      })
      .catch(error => {
        window.alert(error);
      });*/
  }

  const LogoutButton = () => {
    const handleLogout = async () => {
      clearCredentials();
      setOnboardingState(0)
      let signInUri = SIGN_IN_URI;
      if (trainer) {
        signInUri += "/?trainer=" + trainer
      }
      navigate(signInUri);
    };

    return (
      <button onClick={handleLogout}>
        Log out
      </button>
    );
  };

  return (
    <div className={classes.adminContent}>
      <Helmet>
        <script type="application/ld+json">
          {`
                  {
                      "@context": "https://schema.org",
                      "@type": "WebPage",
                      "name": "Admin Panel",
                      "description": "appscribe.io Admin panel",
                      "url": "${window.location.href}",
                      "about": {
                          "@type": "Organization",
                          "name": "Appscribe.io",
                          "description": "Your Easy Fitness App Solution",
                          "address": {
                              "@type": "PostalAddress",
                              "streetAddress": "Beberbeķu iela 46",
                              "addressLocality": "Rīga",
                              "addressRegion": "Vidzeme",
                              "postalCode": "LV-1006",
                              "addressCountry": "Latvia"
                          },
                          "vatID": "LV40203159237"
                      }
                  }
              `}
        </script>
      </Helmet>
      <div
        className={`side-menu ${collapsed && showContent ? 'hidden' : ''}`}
      >
        <div
          className={classes.menuItemContainer}
        >
          <button
            className={`${selected === 1 ? classes.selected : ''}`}
            onClick={() => handleItemClick(1)}
          >
            My subscription
          </button>

          <button
            className={`${selected === 2 ? classes.selected : ''}`}
            onClick={() => handleItemClick(2)}
          >
            Payment methods
          </button>

          <button
            className={`${selected === 3 ? classes.selected : ''}`}
            onClick={() => handleItemClick(3)}
          >
            Invoices
          </button>

          <button
            className={`${selected === 4 ? classes.selected : ''}`}
            onClick={() => handleItemClick(4)}
          >
            Billing info
          </button>
          <LogoutButton />
        </div>
      </div>
      <div
        className={`${classes.content} ${collapsed && !showContent ? classes.hidden : ''}`}
        style={{ backgroundColor: trainerColors?.primaryColor }}
      >
        {collapsed && !showContent && <div onClick={() => handleItemClick(selected)} className={classes.overlay}></div>}
        {collapsed && (
          <div className={classes.header}>
            <div className={classes.backBtn}>
              <IconButton onClick={handleBackClick} aria-label="menu">
                <MenuIcon fontSize="medium" />
              </IconButton>
            </div>
            <div className={classes.logoHolder}>
              <img src={logoPath} alt="Logo" className={classes.menuIcon} />
              <span className={classes.logoText}>{trainerName}</span>
            </div>
          </div>
        )}
        {selected === 1 && <PaymentSubscriptionInfo trainer={trainer} />}
        {selected === 2 && <PaymentMethods setLoadingText={setLoadingText} trainer={trainer} />}
        {selected === 3 && <MyInvoices trainer={trainer} />}
        {selected === 4 && <MyCompanyInfo userData={passedState.userInfo} setLoadingText={setLoadingText} trainer={trainer} />}
      </div>
      {unsubscribeRequestSubId && (
        <Popup onClose={handleCloseUnsubscribe} isOpenProp={isUnsubscribeOpen}>
          <ConfirmUnsubscribePopupContent
            confirmUnsubscribeCallback={confirmUnsubscribeCallback}
            subscriptionId={unsubscribeRequestSubId}
          />
        </Popup>
      )}
      <FullScreenLoader loadingText={loadingText} />
    </div>
  );
}

export default AdminForTrainer;