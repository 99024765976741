import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from 'src/AuthProvider';

import CategoriesReducer from 'src/Utilities/CategoriesReducer';
import TrainingCalendar from '../Categories/PlanCalendar/TrainingCalendar';
import DuplicateDeletePopover from '../Categories/PlanCalendar/DuplicateDeletePopover';
import { generateUniqueId } from 'src/Utilities/Utilities';
import { CategoriesContext } from '../Categories/CategoriesContext';

const VIPCalendar = ({ plan, vipcode, onVipCodeUpdated, onClose, setLoadingText }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorWeekIndex, setAnchorWeekIndex] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const initialPlan = {
        weeks: [{
            index: 0,
            days: []
        }],
    };

    const initialWeek = {
        index: 0,
        days: [
            { index: 0, id: 0, name: '', image: '', exercises: [] },
            { index: 1, id: 1, name: '', image: '', exercises: [] },
            { index: 2, id: 2, name: '', image: '', exercises: [] },
            { index: 3, id: 4, name: '', image: '', exercises: [] },
            { index: 4, id: 5, name: '', image: '', exercises: [] },
            { index: 5, id: 6, name: '', image: '', exercises: [] },
            { index: 6, id: 7, name: '', image: '', exercises: [] }
        ]
    };

    const [localPlan, setLocalPlan] = useState(initialPlan);
    const [localCategory, setLocalCategory] = useState(null);

    const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
    const { loginCredentials, setLoginCredentials } = useContext(AuthContext);
    const { deleteWeek } = useContext(CategoriesContext);

    const [editingExerciseDay, setEditingExerciseDay] = useState({});
    const [openDay, setOpenDay] = useState([]);

    useEffect(() => {
        try {
            const categories = JSON.parse(vipcode.categories);
            setLocalCategory(categories[0])
            setLocalPlan(plan);
        } catch (error) {
            window.alert(error)
        }
    }, [plan]);


    const onEmptyDayClick = (day, week) => {
        if (!day && !week) {
            setEditingExerciseDay({ day: -1, week: -1 })
            return
        }
        setEditingExerciseDay({ day: day.index, week: week.index })
    }

    const onShouldAddExerciseClick = (day, week) => {
        const selectedWeek = localPlan.weeks.find(w => w.index === week.index);
        const selectedDay = selectedWeek.days.find(selectedWeekDay => selectedWeekDay.index === day.index);

        setEditingExerciseDay({ day: day.index, week: week.index })
        setOpenDay(selectedDay);
        setIsSideMenuOpen(true);
    }

    const addWeek = async () => {
        const newWeekIndex = CategoriesReducer.getMaxWeekIndex(localPlan.weeks);
        const newWeek = { days: initialWeek.days, index: newWeekIndex + 1, plan_id: plan.id, id: generateUniqueId() };
        const newLocalPlan = { ...localPlan, weeks: [...localPlan.weeks, newWeek] }

        setLocalPlan(newLocalPlan);
        onPlanUpdateReady(newLocalPlan);
    };

    const onDayUpdated = (newDay) => {
        const updatedPlan = { ...localPlan };
        const { week: weekIndex, day: dayIndex } = editingExerciseDay;

        if (weekIndex >= 0 && weekIndex < updatedPlan.weeks.length &&
            dayIndex >= 0 && dayIndex < updatedPlan.weeks[weekIndex].days.length) {
            updatedPlan.weeks[weekIndex].days[dayIndex] = newDay;

            setLocalPlan(updatedPlan);
            onPlanUpdateReady(updatedPlan);

            if (openDay.id == newDay.id) {
                setOpenDay(newDay)
            }
        }
    };

    const onDoneClick = async () => {
        setIsSideMenuOpen(false);
        setEditingExerciseDay({});

        try {
            onPlanUpdateReady(localPlan);
        } catch (error) {
            window.alert(error);
        }
    };

    const onBackClick = () => {
        setIsSideMenuOpen(false);
        onClose();
    }

    const onDeleteWeekClick = async () => {

        if (anchorWeekIndex == null) {
            window.alert("No week selected")
            return;
        }

        const weekIndex = anchorWeekIndex;
        const isConfirmed = window.confirm("Are you sure you want to delete week " + (weekIndex + 1) + " with all exercises?");

        if (!isConfirmed) {
            return;
        }

        const week = localPlan.weeks[weekIndex]

        try {
            setLoadingText("Deleting week")
            await deleteWeek(week);

            const updatedPlan = {
                ...localPlan,
                weeks: localPlan.weeks
                    .filter((_, index) => index !== weekIndex)
                    .map((week, newIndex) => ({
                        ...week,
                        index: newIndex
                    }))
            };

            setLocalPlan(updatedPlan);
            onPlanUpdateReady(updatedPlan);

            setLoadingText(null)
        } catch (error) {
            window.alert(error);
        }
    }

    const onDuplicateWeekClick = async () => {
        if (anchorWeekIndex == null) {
            window.alert("No week selected")
            return;
        }

        const weekIndex = anchorWeekIndex;
        const isConfirmed = window.confirm("Are you sure you want to duplicate week " + (weekIndex + 1));

        if (!isConfirmed) {
            return;
        }

        const weekToCopy = localPlan.weeks[weekIndex];

        if (!weekToCopy) {
            window.alert("Week does not exist.");
            return;
        }

        // Assign new unique ID to each exercise
        const updatedDays = weekToCopy.days.map(day => ({
            ...day,
            exercises: day.exercises.map(exercise => ({
                ...exercise,
                id: generateUniqueId()
            }))
        }));

        const newWeekIndex = CategoriesReducer.getMaxWeekIndex(localPlan.weeks)
        const newWeek = { 
            days: updatedDays, 
            index: newWeekIndex + 1, 
            plan_id: localPlan.id, 
            id: generateUniqueId() 
        };

        try {
            setLoadingText("Duplicating week");

            const updatedPlan = {
                ...localPlan,
                weeks: [...(localPlan?.weeks || []), newWeek]
            };
            setLocalPlan(updatedPlan);
            onPlanUpdateReady(updatedPlan)
            setLoadingText(null);
        } catch (error) {
            window.alert("Failed to duplicate week: " + error.message);
        }
    }

    const onThreeDotsClick = (event, weekIndex) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setAnchorWeekIndex(weekIndex);
    }

    const handleClose = () => {
        setAnchorEl(null);
        setAnchorWeekIndex(null)
    };

    const onPlanUpdateReady = (updatedPlan) => {
        if (localCategory == null) {
            return
        }

        // Find the plan in localCategory.plans by matching the id
        const updatedPlans = localCategory.plans.map((plan) =>
            plan.id === updatedPlan.id ? updatedPlan : plan  // Replace the plan if the id matches
        );

        // Create a new localCategory with updated plans
        const updatedCategory = {
            ...localCategory,
            plans: updatedPlans  // Update the plans array
        };

        // Update the localCategory state
        setLocalCategory(updatedCategory);

        onVipCodeUpdated(vipcode, updatedCategory);
    };

    if (localCategory == null) {
        return null
    }

    return (
        <>
            <TrainingCalendar
                localPlan={localPlan}
                editingExerciseDay={editingExerciseDay}
                loginCredentials={loginCredentials}
                isSideMenuOpen={isSideMenuOpen}
                openDay={openDay}
                onBackClick={onBackClick}
                onThreeDotsClick={onThreeDotsClick}
                onShouldAddExerciseClick={onShouldAddExerciseClick}
                onEmptyDayClick={onEmptyDayClick}
                addWeek={addWeek}
                onDoneClick={onDoneClick}
                setLoadingText={setLoadingText}
                onDayUpdated={onDayUpdated}
                handleClose={handleClose}
                onDuplicateWeekClick={onDuplicateWeekClick}
                onDeleteWeekClick={onDeleteWeekClick}
                setIsSideMenuOpen={setIsSideMenuOpen}
                useCategoriesNetwork={false}
            />
            {anchorEl &&
                <DuplicateDeletePopover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    onDuplicateClick={() => onDuplicateWeekClick()}
                    onDeleteClick={() => onDeleteWeekClick()}
                />
            }
        </>
    );
};

export default VIPCalendar;
