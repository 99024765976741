import React from 'react';
import BlueButton from 'src/Elements/BlueButton';
import EditableTrainingPlan from './EditableTrainingPlan/EditableTrainingPlan';
import AddEditPlan from './AddEditPlan/AddEditPlan';
import addWorkoutPlanExampleIcon from 'src/Assets/new/addWorkoutPlanExample.jpg';
import { makeStyles } from '@material-ui/core/styles';

const Plans = ({
    state,
    StateEnum,
    localCategory,
    editPlan,
    onAddWorkoutPlanClick,
    handleAddWorkoutPlanClick,
    onPlanClick,
    onPlanAdded,
    onPlanEdited,
    onPlanDeleteClick,
    onPlanEditClick,
}) => {
    const classes = useStyles();

    const PlansListContent = () => (
        <div className={classes.plansContent}>
            {localCategory.plans.map((plan) => (
                <EditableTrainingPlan
                    key={plan.id}
                    plan={plan}
                    onPlanClick={() => onPlanClick(plan)}
                    onDeleteClick={onPlanDeleteClick}
                    onEditClick={onPlanEditClick}
                />
            ))}
        </div>
    );

    const AddWorkoutPlanContent = () => (
        <div className={classes.mainContent}>
            <p>Add your first workout plan</p>
            <div className={classes.addWorkoutPlanButton}>
                <BlueButton onClick={handleAddWorkoutPlanClick}>Add workout plan</BlueButton>
            </div>
        </div>
    );

    const ExampleImageContent = () => (
        <div className={classes.exampleContainer}>
            <img src={addWorkoutPlanExampleIcon} alt="Workout plan example" className={classes.categoriesExampleIcon} />
        </div>
    );

    const RenderContent = () => {
        switch (state) {
            case StateEnum.ADD_WORKOUT_PLAN:
                return (
                    <>
                        {AddWorkoutPlanContent()}
                        {ExampleImageContent()}
                    </>
                );
            case StateEnum.ADD_EDIT_PLAN:
                return (
                    <>
                        <AddEditPlan onPlanAdded={onPlanAdded} editPlan={editPlan} onPlanEdited={onPlanEdited} />
                        {ExampleImageContent()}
                    </>
                );
            case StateEnum.LIST_PLANS:
                return (
                    <div className={classes.plansListContainer}>
                        <header className={classes.header}>
                            <div className={classes.headerButton}>
                                <BlueButton onClick={onAddWorkoutPlanClick}>Add workout plan</BlueButton>
                            </div>
                        </header>
                        {PlansListContent()}
                    </div>
                );
            default:
                return <></>;
        }
    };

    return <>{RenderContent()}</>;
};

const useStyles = makeStyles({
    plansContent: { display: 'flex', gap: 20, flexWrap: 'wrap' },
    mainContent: { flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingRight: 20 },
    exampleContainer: { display: 'flex', flexDirection: 'column', alignItems: 'center' },
    categoriesExampleIcon: { maxWidth: 350 },
    addWorkoutPlanButton: { width: '250px' },
    plansListContainer: { width: '100%' },
    header: { backgroundColor: 'white', padding: 5, borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'space-between', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', marginBottom: 100, width: '100%' },
    headerButton: { marginLeft: 'auto' },
});

export default Plans;
