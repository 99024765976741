import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';
import { NetworkProvider } from 'src/NetworkProvider';
import FullScreenLoader from 'src/Elements/FullScreenLoader';

import { UserTextFields } from '../UserTextfields/UserTextFields.jsx';
import colors from 'src/colors.json';
import { OnboardingState } from '../Enums';
import OnboardingPrompt from 'src/Elements/OnboardingPrompt';
import usePaymentStyles from '../PaymentOnboarding/PaymentStyles.js';

import { SIGN_IN_URI } from 'src/contants';


function ResetPassword() {

    const navigate = useNavigate();
    const [loadingText, setLoadingText] = useState(null);
    const classes = usePaymentStyles();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const authToken = queryParams.get('token');
    const user_id = queryParams.get('u');
    const authorId = queryParams.get('a');
    const isAuthor = authorId === null;

    const logoPath = require(`src/Assets/Logos/appscribe.png`);
    const trainerColors = colors['default']


    async function handleResetPassword(formData, captchaToken) {

        if (captchaToken == null) {
            alert("Failed to validate captcha")
            return;
        }

        try {
            await NetworkProvider.validateCaptchaToken(captchaToken)
        } catch (error) {
            alert("Failed to validate captcha ", error)
            return
        }

        setLoadingText("Resetting password")

        try {
            const { password } = formData;

            if (isAuthor) {
                await NetworkProvider.update_author_password(authToken, user_id, password)
            } else {
                await NetworkProvider.update_user_password(authToken, user_id, password)
            }

            setLoadingText(null)
            if (isAuthor) {
                window.alert("Password has been reset. You will be redirected to sign in");
            } else {
                window.alert("Password has been reset. Log in the app using the new password");
            }
            navigate(SIGN_IN_URI);
        } catch (error) {
            setLoadingText(null)
            alert("Failed to update password, are you sure this email is registered in our system? ")
        }
    }


    function handleSignIn() {
        navigate(SIGN_IN_URI);
    }

    function onFailedValidatingCaptcha(error) {
        setLoadingText(null);
        alert("Failed to validate captcha" + error)
    }

    return (
        <div className={classes.root}>
            <Helmet>
                <script type="application/ld+json">
                    {`
                {
                    "@context": "https://schema.org",
                    "@type": "WebPage",
                    "name": "Onboarding",
                    "description": "Introduce Artificial intelligence power. Stay ahead of your competition with the best customer service 24/7.",
                    "url": "${window.location.href}"
                }
            `}
                </script>
            </Helmet>
            <div className={classes.cardContainer}>
                <div className={classes.logoContainer}>
                    <img src={logoPath} alt="Logo" className={classes.logo} />
                    <span className={classes.logoText}>{"Appscribe"}</span>
                </div>
                <div className={classes.card}
                    style={{
                        backgroundColor: trainerColors?.primaryColor,
                        color: trainerColors?.textPrimaryColor
                    }}>
                    <div className={classes.titleText}>Enter new password</div>
                    <UserTextFields
                        onboardingState={OnboardingState.RESET_PASSWORD}
                        onSubmit={handleResetPassword}
                        onValidatingCaptcha={() => setLoadingText("Validating captcha")}
                        onFailedValidatingCaptcha={onFailedValidatingCaptcha}
                        trainerColors={trainerColors}
                    />
                    <OnboardingPrompt
                        onClick={handleSignIn}
                        onboardingState={OnboardingState.SIGN_IN}
                        trainerColors={trainerColors}
                    />
                </div>
                <FullScreenLoader loadingText={loadingText} />
            </div>
        </div>
    );
}

export default ResetPassword;